import { observable, computed, runInAction, action, toJS } from 'mobx';
import { axiosAuthorizedRequestAdTech, axiosBaseRequestAdTech } from 'requests/helpers';
import { toastErrorNotification } from '../../modules/toast-notifications';
import ContractSelectionStore from '../../redesignSrc/components/ContractSelection1/ContractSelectionStore';
import Common from './Common';
import {
  LegacyUInfo,
  TSMSUserInfo,
  TypeDataParent,
  TypeParentManager,
  TypeReplacements,
} from './TSMS/UserInfo';
import { legacyUserInfo } from './utils/normalize';
import Wallet, { TypeBalance } from './Wallet';

export const IS_PASSABILITY = true;

export type TypeBan = 'legalEntity' | 'individual' | 'selfEmployee' | null

export type TypeCustomersFiles = { id: number, name: string, file: File, }

export const checkTypeBan = (inn?: string | null, isSelfEmployee?: boolean): TypeBan | null => {
  const innLength = inn?.length;

  if (innLength === 10) {
    return 'legalEntity';
  }
  if (innLength === 12 && isSelfEmployee) {
    return 'selfEmployee';
  }
  if (innLength === 12 && !isSelfEmployee) {
    return 'individual';
  }
  return null;
};

export const mapIsTypeBan = (type: TypeBan) => {
  switch (type) {
    case 'individual':
      return 'Индивидуальный предприниматель';
    case 'legalEntity':
      return 'Юридическое лицо';
    case 'selfEmployee':
      return 'Самозанятый';
    default:
      return '-';
  }
};

class UserInfo {
  @observable isShowModalBanStatus = false;
  @observable banStatus = null;
  @observable data: LegacyUInfo | null = null;
  @observable isEditable = false;
  @observable isLoading = true;
  @observable isLoadingPage = true;
  @observable isLoadingUpdateUser = false
  @observable balance: TypeBalance | null = null;
  @observable isCustomerInfoNull = true;
  @observable dataParent: TypeDataParent[] | null = null
  @observable replacementUsers: TypeDataParent[] | null = null
  @observable replacementUser: TypeParentManager | null = null
  @observable replacements: TypeReplacements | null = null
  @observable typeBan: TypeBan = null
  @observable customersFiles: TypeCustomersFiles[] = []
  @observable isCloseData = true

  @action checkBansSendForApproval = async () => {
    // https://servicedesk.veon.com/browse/TSMS-2293
    if (this.data?.company?.ban) {
      const response = await axiosBaseRequestAdTech({
        url: `/api/company/Ban?ban=${this.data?.company?.ban}`,
      });
      this.banStatus = response.banStatus ? response.banStatus.toLowerCase() : response.banStatus;
    }
  };

  @computed get getShouldShowPayInfo() {
    return this.balance?.free_balance === 0 && this.data?.company?.post_pay === false;
  }

  getBalance = async () => {
    this.balance = await axiosBaseRequestAdTech({
      url: '/api/Customers/balance',
    });
    if (this.balance) {
      Wallet.balance = this.balance;
    }
  }

  getSupervisor = async () => {
    try {
      if (this.data?.userType === 'Supervisor' && this.data?.appType === 0) {
        this.dataParent = await axiosAuthorizedRequestAdTech({ url: `api/user/SubordinateUsers?userType=${this.data.id}` });
      }
    } catch (e) {
      console.error(e);
    }
  };


  getReplacementUsers = async () => {
    if (this.data?.appType === 0) {
      this.replacementUsers = await axiosAuthorizedRequestAdTech({ url: 'api/user/selfSolutionUsers' });
    }
  };

  getUser = async (force = false, source = '') => {
    if (this.data && !force) {
      return;
    }
    const response = await axiosAuthorizedRequestAdTech({ url: '/api/user/info' });
    this.isCustomerInfoNull = response.customerInfo === null;
    if (!this.isCustomerInfoNull) {
      await this.getCustomersFiles();
    }
    this.isLoadingPage = false;
    runInAction(() => {
      this.data = legacyUserInfo(response);
      this.typeBan = checkTypeBan(this.data?.company.inn, this.data?.company.isSelfEmployee);
      TSMSUserInfo.data = response;
    });
    await this.getSupervisor();
    await this.getReplacementUsers();
    await this.getReplacementUser();
    if (!ContractSelectionStore.bans.length && this.isAggregator && location.pathname.includes('user-info')) {
      await ContractSelectionStore.getBans();
    }
  };

  getReplacementUser = async (id?: number) => {
    const response = await axiosAuthorizedRequestAdTech({ url: `/api/user/replacements?userId=${id || this.data?.id}` }) as TypeReplacements[];
    if (response?.length && response?.length > 0) {
      this.replacementUser = response[0]?.replacementUser;
      this.replacements = response[0];
    } else {
      this.replacementUser = null;
      this.replacements = null;
    }
    return response;
  }

  getCustomersFiles = async () => {
    try {
      const response = await axiosAuthorizedRequestAdTech({ url: '/api/customers/files' });
      if (response?.length) {
        this.customersFiles = response;
      }
    } catch (e) {
      console.error(e);
    }
  }

  loadCustomersFiles = async (files?: TypeCustomersFiles[]) => {
    try {
      this.isLoadingUpdateUser = true;
      if (this.data?.company.id) {
        const currentFiles = files || this.customersFiles;
        await Promise.all(currentFiles.map(el => {
          const formData = new FormData();
          formData.append('file', el.file);

          return axiosBaseRequestAdTech({
            url: `/api/Customers/${this.data?.company.id}/uploadDocs/`,
            method: 'POST',
            data: formData,
          }).then((res: any) => res.id);
        }));
        await this.getCustomersFiles();
      } else {
        this.customersFiles = [...this.customersFiles, ...(files || [])];
      }
    } catch (e) {
      console.error(e);
      toastErrorNotification('Во время выполнения запроса произошла ошибка');
    } finally {
      this.isLoadingUpdateUser = false;
    }
  }

  submitTestSmS = async (compainId: number | null, phone: string) => {
    await axiosAuthorizedRequestAdTech({ url: `/api/campaign/${compainId}/TestSend?ctn=${phone}` });
  }

  // checkForNotificationAfterLogIn = async () => {
  //   if (this.data?.company?.payments_warnings.length && this.data?.late_payment === false) {
  //     const response = await confirmDialog(BillDebt);
  //     if (response === 'changeEmail') {
  //       await confirmDialog(ChangeEmail);
  //     }
  //   } else if (this.data?.late_payment) {
  //     const response = await confirmDialog(LockedService);
  //     if (response === 'changeEmail') {
  //       await confirmDialog(ChangeEmail);
  //     }
  //   }
  // }

  @action updateUser = async () => {
    this.isLoadingUpdateUser = true;
    try {
      await axiosAuthorizedRequestAdTech({
        url: '/api/user/info',
        method: 'PUT',
        params: {
          FirstName: this.data?.first_name,
          MiddleName: this.data?.middle_name,
          LastName: this.data?.last_name,
          Email: this.data?.email,
          Phone: this.data?.phone,
          IndustryBeePro: this.data?.company?.industry,
          SocialVkontakte: this.data?.company?.social_vkontakte,
          Website: this.data?.company?.website,
        },
      });
      return true;
    } catch (e) {
      throw e.response.data;
    } finally {
      runInAction(() => {
        this.isLoadingUpdateUser = false;
        this.isEditable = false;
      });
    }
  };

  @computed get username() {
    const lastName = this.data?.last_name || '';
    const firstName = this.data?.first_name || '';
    const middleName = this.data?.middle_name || '';
    const fullName = `${lastName} ${firstName[0] || ''}.${middleName[0] || ''}.`;
    const fullNameNotAbbr = `${lastName} ${firstName} ${middleName}`;
    const name = this.data?.username;
    return {
      lastName,
      firstName,
      middleName,
      fullName,
      fullNameNotAbbr,
      name,
    };
  }

  @computed get industryLabel() {
    return Common.industries.find(el => el.value === this.data?.company.industry)?.label || '-';
  }

  @computed get selectedIndustryLabel() {
    if (Common?.industries.length && this.data?.company?.industry) {
      const industry = Common.industries.filter(item => item.value === this.data?.company?.industry)[0];
      return industry?.label || '';
    }
    return '';
  }

  @computed get isRoleSS() {
    return this.data?.appType === 0 && (this.data?.userType === 'Employee' || this.data?.userType === 'Supervisor');
  }

  @computed get isDataParent() {
    return this.dataParent && this.dataParent.length > 0;
  }

  @computed get isAggregator() {
    return this.data?.company?.type === 1 || this.data?.company?.type === 2;
  }
}

const userInfoStore = new UserInfo();
export default userInfoStore;
