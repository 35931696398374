import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { debounce } from 'debounce';
import { navigate } from '@reach/router';
import { GoBackBtn } from 'redesignSrc/UI/elements/CardBottomPanelBtns/components/GoBackBtn';
import { SaveDraftBtn } from 'redesignSrc/UI/elements/CardBottomPanelBtns/components/SaveDraftBtn';
import { NextBtn } from 'redesignSrc/UI/elements/CardBottomPanelBtns/components/NextBtn';
import { FormFieldLabel, GroupTitle } from 'redesignSrc/UI/typography';
import { ContentContainer } from 'redesignSrc/UI/layout';
import {
  composeActivityValidator,
  composeNumberValidator, composeRequiredValidator, composeStartDateValidator,
} from 'utils/fieldValidators';
import { formatPrice } from 'utils/formatting';
import DateSelection from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/DateSelection';
import useValidation from 'redesignSrc/HOC/hooks/useValidation';
import { scrollSmoothToNodeById } from 'utils/scroll';
import NewOrder from 'store/mobx/NewOrder';
import { WorkingHours } from 'redesignSrc/UI/elements/WorkingHours/WorkingHours';
import { CardBottomPanelBtns } from 'redesignSrc/UI/elements/CardBottomPanelBtns/CardBottomPanelBtns';
import UserInfo from 'store/mobx/UserInfo';
import CampaignProgress from 'redesignSrc/components/CampaignProgress';
import { WrapperPage } from 'components/common/WrapperPage/WrapperPage';
import { ORDER } from '../../constants';
import { TypeIndustries } from '../index';
import ContainerSticky from '../../../UI/ContainerSticky';
import WidgetAudience from '../../../components/WidgetAudience';
import { addDays } from '../../../../utils/date';
import NewAudiences from '../../../../store/mobx/NewAudiences';
import { ym } from '../../../../utils/ga-analytics/utils';
import ContractSelectionSS from '../../../components/ContractSelectionSS';
import styles from './styles.pcss';
import { ConnectionTypeBlock, WaysToMakeCalls, CoastPerActionBlock, BudgetVoice } from './components';


const saveWithDebounce = debounce(async () => {
  await NewOrder.saveOrder();
  await NewOrder.calculate();
}, 500);

type Props = {
  industries: TypeIndustries
}

export const newYearBudget = () => {
  return new Date() > new Date(2024, 12, 30) ?
    60000 : 12000;
};

const VoicePageSetup: React.FC<Props> = ({ industries }) => {
  const minBudget = NewOrder.voiceTargetDetails.audienceType === 'Custom' ? 120000 : newYearBudget();
  const [isFirstSetCustomer, setIsFirstSetCustomer] = useState(true);


  const rulesObj = {
    industryId: NewOrder.order?.isSavedAudience ? [composeRequiredValidator('Обязательное поле')] : undefined,
    [ORDER.BUDGET_FIELD]: [
      composeNumberValidator(`Сумма не может быть ниже ${formatPrice(minBudget)} ₽`, { min: minBudget }),
    ],
    [ORDER.INDUSTRY_ID]: [
      composeActivityValidator(
        'Укажите отрасль, тип товара или услуги, чтобы рассчитать стоимость',
        NewOrder.voiceTargetDetails.audienceType,
      ),
    ],
    [ORDER.START_DATE_FIELD]: [
      composeRequiredValidator('Дата старта — обязательное поле'),
      composeStartDateValidator('Выберите доступную дату старта из календаря', addDays(new Date(), 2)),
    ],
    [ORDER.FINISH_DATE_FIELD]: [
      composeRequiredValidator('Дата завершения — обязательное поле'),
    ],
  };


  // useEffect(() => {
  //   (async () => {
  //     if (!NewAudiences?.audience) return;
  //     NewOrder.calculateData?.count && NewOrder.isValidSizeSegment(NewAudiences?.audience, NewOrder.calculateData?.count);
  //     await NewAudiences.getSegment(NewOrder.order?.segmentId);
  //   })();
  // }, [NewOrder.calculateData]);

  useEffect(() => {
    (async () => {
      if (!NewAudiences.timerId && NewAudiences.status === 'Succeeded') {
        (NewOrder.calculateData?.count && NewAudiences?.msisdnsCount) && NewOrder.isValidSizeSegment(NewAudiences?.msisdnsCount, NewOrder.calculateData?.count, NewAudiences.isRealTime);
      } else if (!NewAudiences.timerId && NewAudiences?.audience) {
        NewOrder.calculateData?.count && NewOrder.isValidSizeSegment(NewAudiences?.audience, NewOrder.calculateData?.count, NewAudiences.isRealTime);
      }
    })();
  }, [NewAudiences.status, NewAudiences?.audience, NewOrder.calculateData, NewOrder.calculateData?.count, NewAudiences.timerId]);

  const valid = useValidation({
    currentOrder: NewOrder.currentOrder,
    voiceTargetDetails: NewOrder.voiceTargetDetails,
  }, rulesObj);

  const handleNext = async () => {
    const { firstErrorKey } = valid.getAllValid();
    if (firstErrorKey) {
      scrollSmoothToNodeById(firstErrorKey, 200);
      return;
    }
    let type;
    if (window.location.pathname.includes('audience')) type = 'audience';
    if (window.location.pathname.includes('channels')) type = 'channels';
    if (type) {
      ym(87718585, 'reachGoal', 'step', { create_campaign: { step: { [NewOrder?.order?.type || '']: type } } });
    }
    navigate(`/new-campaign/${NewOrder.order?.id}/channels/VoiceTarget/confirmation/`);
  };

  return (
    <WrapperPage>
      <div className={styles.mainWrap}>
        <section>
          <CampaignProgress
            title="Создание кампании"
            step={2}
            description={`Шаг 2 из ${UserInfo?.data?.company?.ban ? 3 : 4}. Настройте рассылку`}
            totalStep={UserInfo?.data?.company?.ban ? 3 : 4}
          />
          {NewOrder.order?.isSavedAudience &&
            <ContractSelectionSS
              validation={valid}
              customerId={NewOrder.order?.customerId}
              onChange={async () => {
                if (isFirstSetCustomer) {
                  await NewOrder.saveOrder();
                  await NewOrder.calculate();
                }
                setIsFirstSetCustomer(false);
              }}
            />
          }
          <ConnectionTypeBlock onChange={() => saveWithDebounce()}/>
          {
            NewOrder.voiceTargetDetails.audienceType === 'NotTarget' &&
            <WaysToMakeCalls onChange={() => saveWithDebounce()}/>
          }
          <ContentContainer className={styles.voiceCall}>
            <GroupTitle className={styles.mainTitle}>Голосовой обзвон</GroupTitle>


            {// @ts-ignore
              NewOrder.voiceTargetDetails.audienceType !== 'Custom' &&
              <CoastPerActionBlock
                validation={valid}
                name={ORDER.INDUSTRY_ID}
                handlerChange={() => saveWithDebounce()}
                industries={industries}
              />}

            <BudgetVoice
              validation={valid}
              onChange={() => saveWithDebounce()}
            />
            <div className={styles.wrapperDateSelection}>
              <FormFieldLabel>Период обзвона</FormFieldLabel>
              <DateSelection
                isVoice
                handleSmsFormChange={saveWithDebounce}
                validation={valid}
              />
            </div>

          </ContentContainer>

          <WorkingHours/>
          <CardBottomPanelBtns>
            <>

              {!NewOrder.order?.isSavedAudience &&
                <GoBackBtn
                  onClick={async () => {
                    await NewAudiences.getSegment(NewOrder.order?.segmentId || NewOrder.currentOrder?.segmentId);
                    navigate(`/new-campaign/${NewOrder.order?.id}/audience`);
                  }}
                />
              }

              {NewOrder.order?.status === 'Draft' &&
                <SaveDraftBtn
                  onClick={async () => {
                    if (NewOrder.order?.id) {
                      await NewOrder.setDraft(NewOrder.order?.id);
                      await navigate('/my-campaigns/');
                    }
                  }}
                />
              }

              <NextBtn
                onClick={handleNext}
              />

            </>
          </CardBottomPanelBtns>
        </section>

        <ContainerSticky className={styles.wrapperSticky}>
          <WidgetAudience/>
        </ContainerSticky>
      </div>

    </WrapperPage>
  );
};

export default observer(VoicePageSetup);
