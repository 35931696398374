import React, { useEffect, useState } from 'react';
import { IconButton, Button } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import NewOrder from 'store/mobx/NewOrder';
import NewAudiences from 'store/mobx/NewAudiences';
import { wordFormByCount } from 'utils/strings';
import { formatPriceTotal, formatPriceWithLabel } from 'utils/formatting';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import {
  SMSCounter,
} from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/components/MessageRemainingLength/utils';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import { scrollSmoothToNodeById } from 'utils/scroll';
import TooltipWrapperClick from 'redesignSrc/UI/elements/TooltipWrapperClick';
import { PureButton } from 'redesignSrc/UI/buttons';
import wikiStore from 'store/mobx/WikiStore';
import styles from './style.pcss';

const BASE_SMS = 2.6;


const useUtilsCount = (isTemplateMessage?: boolean) => {
  const partForms = ['часть', 'части', 'частей'];
  const simbolForms = ['символ', 'символа', 'символов'];
  const screenForms = ['экран', 'экрана', 'экранов'];
  const [priceParts, setPriceParts] = useState<number[] | null>(null);

  useEffect(() => {
    if (priceParts) return;
    (async () => {
      try {
        const { pricePartsCost } = await axiosBaseRequestAdTech({ url: '/api/settings', method: 'GET' });
        setPriceParts(pricePartsCost);
      } catch (error) {
        setPriceParts(null);
        console.error(error);
      }
    })();
  }, []);

  const ShowTooltipBadStatus = () => {
    return (
      <TooltipWrapperClick
        className={styles.badStatusButton}
        containerClassname={styles.containerClassname}

        content={
          <div className={styles.contentWrap}>
            <h5>С вами будут конкурировать много рассылок</h5>
            <p>Рекламная кампания может длиться долго.
              Эффект от длительной рекламной кампании часто не заметен</p>
            <PureButton
              className={styles.contentWrapButton}
              onClick={() => {
                scrollSmoothToNodeById('auction');
              }}
            >
              Изменить стоимость
            </PureButton>
          </div>
        }
      >
        <Button size="small" variant="contained">Высокая конкуренция</Button>
      </TooltipWrapperClick>
    );
  };

  const ShowTooltipAuction = () => {
    return (
      <TooltipWrapperClick
        containerClassname={styles.wrapAuction}
        content={
          <div className={styles.textAuction}>
            Указана стоимость за все выбранные параметры реального времени, рекомендованная системой или установленная
            вами в&nbsp;
            <PureButton
              className={styles.linkAuction}
              onClick={() => {
                NewAudiences.isInfoVisible = true;
                wikiStore.activeWiki = 'Настройка аудитории';
              }}
            >
              формате аукциона
            </PureButton>
          </div>
        }
      >
        <IconButton iconName={Icons.InfoCircled}/>
      </TooltipWrapperClick>


    );
  };

  // Старый репрайс потом удалить
  const showTooltipText1 = (isOther?: boolean) => {
    const pricePart = NewOrder.calculateData?.isFixedPrice ? NewOrder.costBase : NewOrder.costBase / NewOrder.countBase;
    const coustBase = NewOrder.calculateData?.isFixedPrice ? NewOrder.costBase * NewOrder.countBase : NewOrder.costBase;
    const titleFixAudience = NewOrder.calculateData?.costs && NewOrder.calculateData?.costs[1] && NewOrder.calculateData?.costs[1]?.title || '';
    const priceFixAudience = NewOrder.calculateData?.costs && NewOrder.calculateData?.costs[1] && NewOrder.calculateData?.costs[1]?.cost;
    const geoPrice = NewAudiences.selectedSegments?.find(segment => segment.bundleType === 'Geo')?.price || '-';
    if (isOther) {
      return (
        <>
          <p>Стоимость 1 сообщения на другие операторы:</p>
          <p>
            {`1 часть: ${formatPriceTotal(6)}.
         Всего за ${NewOrder.countBase} ${wordFormByCount(NewOrder.countBase, partForms)}:
          ${formatPriceTotal(((Math.ceil(SMSCounter(NewOrder?.order?.smsCampaign?.shortMessage?.length || 1)))) * 6)}`}
          </p>
        </>
      );
    }
    return (
      <>
        <p>Стоимость 1 сообщения на билайн:</p>
        <p>
          {`1 часть: ${formatPriceTotal((pricePart).toFixed(1))}.
         Всего за ${NewOrder.countBase} ${wordFormByCount(NewOrder.countBase, partForms)}: ${formatPriceTotal((coustBase).toFixed(1))}`}
        </p>

        {NewAudiences.isSimple &&
          <p>{`Подбор аудитории  +${formatPriceTotal(geoPrice)}`}</p>
        }

        {NewAudiences.isCustom &&
          <p>{`Параметры инд. аудитории  +${formatPriceTotal(NewAudiences.OTHER_PRICE)}`}</p>
        }

        {NewOrder.calculateData?.isFixedPrice &&
          <p>{`${titleFixAudience}  +${formatPriceTotal(priceFixAudience)}`}</p>
        }

        {!NewAudiences.isNotRenderPrice && !NewOrder.calculateData?.isFixedPrice ? NewAudiences.renderPriceSegment.map(segment => {
          return (
            <p>{`${segment.title}  +${formatPriceTotal(segment.price)}`}</p>
          );
        }) : null}

      </>
    );
  };
  // Новый репрайс
  const showTooltipText2 = (isOther?: boolean) => {
    const geoPrice = NewAudiences.selectedSegments?.find(segment => segment.bundleType === 'Geo')?.price || '-';
    const pricePart = NewOrder.costBase;
    const coustBase = NewOrder.costBase * NewOrder.countBase;
    const titleFixAudience = NewOrder.calculateData?.costs && NewOrder.calculateData?.costs[1] && NewOrder.calculateData?.costs[1]?.title || '';
    const priceFixAudience = NewOrder.calculateData?.costs && NewOrder.calculateData?.costs[1] && NewOrder.calculateData?.costs[1]?.cost;
    if (isOther) {
      return (
        <>
          <p>Стоимость 1 сообщения на другие операторы:</p>
          <p>
            {`1 часть: ${formatPriceTotal(6)}.
         Всего за ${NewOrder.countBase} ${wordFormByCount(NewOrder.countBase, partForms)}:
          ${formatPriceTotal(((Math.ceil(SMSCounter(NewOrder?.order?.smsCampaign?.shortMessage?.length || 1)))) * 6)}`}
          </p>
        </>
      );
    }
    return (
      <>
        <p>Стоимость 1 сообщения на билайн:</p>
        {NewOrder.calculateData?.isFixedPrice || NewOrder.calculateData?.isFixedPartPrice ?
          <p>
            {`1 часть: ${formatPriceTotal((pricePart).toFixed(1))}.
         Всего за ${NewOrder.countBase} ${wordFormByCount(NewOrder.countBase, partForms)}: ${formatPriceTotal((coustBase).toFixed(1))}`}
          </p> :
          <p>{NewOrder.tooltip}</p>
        }


        <>
          {!NewAudiences.isNotRenderPrice && !NewOrder.calculateData?.isFixedPrice ? NewAudiences.renderPriceSegment.map(segment => {
            return (
              <p>{`${segment.title}  +${formatPriceTotal(segment.price)}`}</p>
            );
          }) : null}

          {NewOrder.calculateData?.isFixedPrice &&
            <p>{`${titleFixAudience}  +${formatPriceTotal(priceFixAudience)}`}</p>
          }

          {NewAudiences.isSimple &&
            <p>{`Подбор аудитории  +${formatPriceTotal(geoPrice)}`}</p>
          }
          {NewAudiences.isCustom &&
            <p>{`Параметры инд. аудитории  +${formatPriceTotal(NewAudiences.OTHER_PRICE)}`}</p>
          }
        </>
      </>
    );
  };

  const showDefaultPrice = () => {
    // Упростить логику

    if (isTemplateMessage) {
      if (!messageTemplatesStore.formTemplate.values.shortTemplate) {
        return '1 SMS (до 70 симв.)';
      }
      return `1 SMS (${messageTemplatesStore.lengthSms} ${wordFormByCount(messageTemplatesStore.lengthSms, simbolForms)})`;
    }
    if (NewOrder.order?.type === 'Sms') {
      if (NewAudiences.isMultiOperators) {
        if (!NewOrder.order?.smsCampaign?.shortMessage) {
          return '1 SMS';
        }
        return `1 SMS билайн (${NewOrder.lengthSms}
       ${wordFormByCount(NewOrder.lengthSms, simbolForms)})`;
      }
      if (!NewOrder.order?.smsCampaign?.shortMessage) {
        return '1 SMS';
      }
      // return `1 SMS (${NewOrder.lengthSms} симв. ${SMSCounter(NewOrder.lengthSms || 1)} ${wordFormByCount(SMSCounter(NewOrder.lengthSms || 1), partForms)})`;
      return '1 SMS';
    }

    if (NewOrder.order?.type === 'Push') {
      if (!NewOrder?.order?.pushCampaign?.pages?.length) {
        return '1 PUSH (до 99 симв.)';
      }
      return `1 PUSH (${NewOrder?.order?.pushCampaign?.pages?.length || 1} ${wordFormByCount(Math.ceil(NewOrder?.order?.pushCampaign?.pages?.length || 1), screenForms)})`;
    }
    return '';
  };

  const showTotalPrice = () => NewOrder.totalPrice ? formatPriceTotal(NewOrder.totalPrice) : null;

  const renderBaseSmsForOper = () => {
    return (
      <>
        <span className={styles.color}>
          Базовое SMS для других операторов
          ({SMSCounter(NewOrder.lengthSms || 1)}
          &nbsp;{wordFormByCount(SMSCounter(NewOrder.lengthSms || 1), partForms)})<br/>
        </span>
        <span>
          {formatPriceTotal(SMSCounter((NewOrder.lengthSms || 1)) * 6)}
        </span>
      </>
    );
  };

  const renderBaseSmsForOtherOper = () => {
    return !NewOrder?.order?.smsCampaign?.message ? ' (до 70 симв.)'
      :
      `(${NewOrder.lengthSms || 1}
      ${wordFormByCount(NewOrder.lengthSms || 1, simbolForms)})`;
  };

  const renderBaseSmsPriceForOtherOper = () => {
    // 6 vmesto OTHER_PRICE
    return formatPriceTotal(((Math.ceil(SMSCounter(NewOrder?.order?.smsCampaign?.shortMessage?.length || 1)))) * 6);
  };

  const priceBase = () => {
    if (NewOrder.order?.type !== 'Sms' && NewOrder.order?.type !== 'Push') return;
    if (isTemplateMessage) {
      return formatPriceTotal(((BASE_SMS * (Math.ceil(SMSCounter(
        messageTemplatesStore.formTemplate.values.shortTemplate?.length || 1))))).toFixed(1),
      );
    }
    if (NewOrder.order?.type === 'Sms') {
      return `${formatPriceTotal(+(NewOrder.costBase * NewOrder.countBase).toFixed(1))}`;
    }
    const factor = NewOrder.countBase >= 3 ? 2 : 1;
    return formatPriceTotal(+(NewOrder.costBase * factor).toFixed(1));
  };

  const renderFixedPrice = () => {
    return NewOrder.calculateData?.costs?.length && (NewOrder.calculateData?.costs?.length > 0) && NewOrder.calculateData?.costs?.map((cost) => {
      return (
        <p className={styles.description}>
          <span>{cost.title}</span>
          <span className={styles.fixCost}>{cost.cost}</span>
        </p>
      );
    });
  };

  const renderBase = () => {
    if (NewOrder.order?.type !== 'Sms' && NewOrder.order?.type !== 'Push' && !isTemplateMessage) return;
    if (isTemplateMessage) {
      return `Базовое SMS (${SMSCounter(messageTemplatesStore.lengthSms || 1)}
     ${wordFormByCount(SMSCounter(messageTemplatesStore.lengthSms || 1), partForms)})`;
    }
    if (NewOrder.order?.type === 'Sms') {
      return `Базовое SMS (${SMSCounter(NewOrder.lengthSms || 1)}
     ${wordFormByCount(SMSCounter(NewOrder.lengthSms || 1), partForms)})`;
    }
    return `Базовое PUSH (${NewOrder?.order?.pushCampaign?.pages?.length || 1}
                        ${wordFormByCount(Math.ceil(NewOrder?.order?.pushCampaign?.pages?.length || 1), screenForms)})`;
  };

  const withCustomAudiencesPrice = (price: number) => {
    return NewAudiences.isCustom ? price + 5.5 : price;
  };

  const sumArrayElements = (n: number) => {
    // const arr = [8.0, 0, 0, 0, 4.0, 0, 0, 0, 0, 0];
    if (!priceParts?.length) return 0;

    if (n < 1 || n > priceParts?.length) {
      return 1;
    }

    let sum = 0;
    for (let i = 0; i < n; i++) {
      sum += priceParts[i];
    }

    return sum.toFixed(1);
  };

  const showTitlePrice = () => {
    if (NewOrder.order?.type === 'Sms' && NewOrder.calculateData?.isFixedPrice) {
      return formatPriceTotal((NewOrder.calculateData?.eventCost).toFixed(1));
    }
    const geoSegment = NewAudiences.selectedSegments.find(segment => segment.bundleType === 'Geo');
    const sumPriceSegment = NewAudiences.isPriceOld ? NewAudiences.oldPrice || 0 : withCustomAudiencesPrice(NewAudiences.sumPriceSegment);
    if ((NewOrder.order?.type === 'Sms' || NewOrder.order?.type === 'Push') && NewOrder.calculateData && NewAudiences?.isSimple) {
      const isSegmentExist = NewOrder.currentOrder.segmentId;
      let total = NewOrder.calculateData?.eventCost + (geoSegment?.price || 0);
      // if (isSegmentExist && NewOrder.calculateData.costs.length > 1) {
      //   total = NewOrder.calculateData.costs?.reduce((a, b) => a + b?.cost, 0);
      // }
      if (NewOrder.order?.type === 'Sms' && (isSegmentExist && NewOrder.calculateData.costs.length > 1)) {
        total = NewOrder.calculateData?.eventCost;
      }
      if (NewOrder.order?.type === 'Push' && isSegmentExist) {
        total = NewOrder.calculateData?.eventCost;
      }
      return formatPriceTotal(total.toFixed(1));
    }
    if (NewAudiences.isLookAlike) {
      const sumPrice = (typeof geoSegment?.price === 'number' ? geoSegment?.price : 0);
      return NewOrder.calculateData?.isFixedPrice ?
        formatPriceTotal((NewOrder.costBase * NewOrder.countBase + sumPrice).toFixed(1)) :
        formatPriceTotal((NewOrder.costBase + sumPrice).toFixed(1));
    }
    if (isTemplateMessage) {
      // return formatPriceTotal(((BASE_SMS * (Math.ceil(SMSCounter(
      //   messageTemplatesStore.lengthSms || 1))))).toFixed(1),
      // );
      return formatPriceTotal(sumArrayElements(SMSCounter(messageTemplatesStore.lengthSms || 1)));
    }
    if (NewOrder.order?.type === 'Sms') {
      return NewOrder.calculateData?.isFixedPrice ?
        formatPriceTotal((NewOrder.costBase * NewOrder.countBase + sumPriceSegment).toFixed(1)) :
        formatPriceTotal((NewOrder.costBase + sumPriceSegment).toFixed(1));
    }
    const factor = NewOrder.countBase >= 3 ? 2 : 1;
    return formatPriceTotal((NewOrder.costBase * factor + sumPriceSegment).toFixed(1));
  };

  const renderSimpleAudience = () => {
    const geoPrice = NewAudiences.selectedSegments?.find(segment => segment.bundleType === 'Geo')?.price || '-';
    return (
      <>
        <p className={styles.description}>
          <span>{renderBase()}</span>
          <span className={styles.fixPrice}>{priceBase()}</span>
        </p>
        <p className={styles.description}>
          Подбор аудитории
          <span>{formatPriceTotal(geoPrice)}</span>
        </p>
      </>
    );
  };

  const renderAudioPrice = () => {
    if (NewOrder.totalPrice > 0) {
      return (
        <>
          {NewOrder.calculateData?.costs.map((el, i) => {
            const title = i === 0 ? `Ролик ${el.title}` : el.title;
            const description = i === 0 ? el.cost : el.count;
            return (
              <p className={styles.description}>
                {title}
                <span>{description}</span>
              </p>
            );
          })}

        </>
      );
    }
    return (
      <p className={styles.firstInfo}>
        Высчитывается исходя из количества выбранных магазинов, периода размещения
        и длительности ролика
      </p>
    );
  };

  const renderTotalCost = () => {
    if (NewOrder.order?.type === 'Sms') {
      return (
        <>
          {NewOrder.countMessage ?
            <p className={styles.descriptionSms}>
              Количество
              <span>
                {NewOrder.countMessage}
              </span>
            </p> : null
          }
          {NewOrder.calculateData?.senderNameCost ?
            <p className={styles.description}>
              Имя отправителя
              <span>
                {formatPriceWithLabel(NewOrder.calculateData?.senderNameCost)}
              </span>
            </p> : null
          }
        </>
      );
    }
    return null;
  };


  return {
    showDefaultPrice,
    renderBaseSmsForOper,
    renderBaseSmsForOtherOper,
    renderBaseSmsPriceForOtherOper,
    renderBase,
    showTitlePrice,
    priceBase,
    showTotalPrice,
    renderTotalCost,
    renderAudioPrice,
    renderFixedPrice,
    isTemplateMessage,
    renderSimpleAudience,
    showTooltipText1,
    showTooltipText2,
    ShowTooltipAuction,
    ShowTooltipBadStatus,
  };
};

export default useUtilsCount;
