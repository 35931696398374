import { observable, computed, runInAction, action, toJS } from 'mobx';

export const TITLE_WIKI_GEO_REALTIME = 'Как работать с геолокацией в реальном времени?';

class WikiStore {
  @observable activeWiki: string | null = null
  @observable isAboutMoreConfirmationWiki: boolean = false;
  @observable isInfoVisibleGeoRealTime: boolean = false
}

const wikiStore = new WikiStore();

export default wikiStore;
