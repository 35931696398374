export type MStatuses = 'Enqueued' | 'Failed' | 'Succeeded' | 'Deleted' | 'Processing'

export type TypeAudience = {
  id: number,
  name: string,
  isExternal: boolean,
  price: null | number,
  lastModified: string,
  createDate: string,
  creator: string,
  msisdnsCount: null | number,
  isMyAudience: boolean,
  status: MStatuses,
  type: keyof typeof SEGMENT_TYPE,
  key?: number,
}

export const SEGMENT_TYPE = {
  Offline: 'По истории поведения', // 0
  RealTime: 'В реальном времени', // 1
  Custom: 'Индивидуальный запрос', // 2
  MultiOperators: 'По трем операторам', // 3
  LookAlike: 'Похожая аудитория', // 4
  Simple: 'Автоматический подбор', // 5
};

export type TypeRequestSegment = {
  pageCount: number,
  segments: TypeAudience[]
}


export type TypeRequestJobs = {
  pageCount: number,
  jobs: TypeAudience[]
}
