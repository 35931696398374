import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { SMS_LEGAL_INFO_MAX_LENGTH } from 'store/NewCampaign/channels/constants';
import InfoDescription from 'redesignSrc/components/InfoDescription';
import CounterFieldText from 'redesignSrc/UI/form/CounterFieldText';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import { HovesCardTitle } from 'redesignSrc/UI/typography';
import withErrorStopWords from 'redesignSrc/HOC/withErrorStopWords';
import { AboutWhoAndWhatToWrite } from 'redesignSrc/pages/NewAudience/components/FloatingContent/AboutWhoAndWhatToWrite';
import FloatingSidePanel from 'pages/MyFinances/components/FloatingPanel';
import wikiStore from 'store/mobx/WikiStore';
import { LegalInfoProps } from './LegalInfoProps';
import styles from './styles.pcss';


const CounterFieldTextStopWords = withErrorStopWords(CounterFieldText);

export const LegalInfo: React.FC<LegalInfoProps> = observer(({ labelText, handleSmsFormChange, valid }) => {
  const [value, setValue] = useState(messageTemplatesStore.formTemplate.values?.additionalTemplate || '');
  const [wikiWhoAndWhatToWrite, setWikiWhoAndWhatToWrite] = useState(false);
  useEffect(() => {
    messageTemplatesStore.formTemplate.values.additionalTemplate = value.replace(/ +/g, ' ').trim().replace(/\n+/g, '\n').trim();
    handleSmsFormChange && handleSmsFormChange();
  }, [value]);

  useEffect(() => {
    if (wikiStore.activeWiki !== 'Кому и что писать' && wikiWhoAndWhatToWrite) {
      setWikiWhoAndWhatToWrite(false);
    }
  }, [wikiStore.activeWiki]);

  const isDisabledField = () => {
    return messageTemplatesStore.formTemplate.values.status === 'Verification' || messageTemplatesStore.formTemplate.values.status === 'Accepted';
  };

  return (
    <div className={styles.wrapperLegalArea}>
      <HovesCardTitle className={styles.title}>Дополнительная юридическая информация</HovesCardTitle>
      <CounterFieldTextStopWords
        label={labelText}
        onChange={(e: any) => {
          setValue(e);
        }}
        error={valid.errors.additionalTemplate}
        onErrorWords={(isErr, errorWords) => {
          if (isErr) {
            valid.setErrorsOfKey(
              'additionalTemplate',
              `Данное слово запрещено использовать в описании, пожалуйста, замените его. Использованные запрещенные слова: ${errorWords.toString()}`,
            );
          } else {
            valid.setErrorsOfKey('additionalTemplate', '');
          }
        }}
        name="additionalTemplate"
        onBlur={() => {
          setValue(value.replace(/ +/g, ' ').trim().replace(/\n+/g, '\n').trim());
        }}
        value={value}
        max={SMS_LEGAL_INFO_MAX_LENGTH}
        disabled={isDisabledField()}
        placeholder="Например, не является лекарством"
      />

      <InfoDescription icon="yellow">
        <span className={styles.warning}>
          Обязательно заполните поле, если продаёте товары дистанционно, рекламируете финансовые или медицинские услуги.&nbsp;
          <span
            onClick={() => {
              wikiStore.activeWiki = 'Кому и что писать';
              setWikiWhoAndWhatToWrite(true);
            }}
            className={styles.link}
          >
            Кому и что писать.
          </span>
          &nbsp;Данный текст появится после основного текста сообщения <span>(бесплатно)</span>
        </span>
      </InfoDescription>
      {
        wikiWhoAndWhatToWrite &&
        <FloatingSidePanel
          onClose={() => {
          wikiStore.activeWiki = null;
          setWikiWhoAndWhatToWrite(false);
          }}
          title="Памятка к обязательной юридической информации"
        >
          <AboutWhoAndWhatToWrite/>
        </FloatingSidePanel>
      }
    </div>
  );
});

