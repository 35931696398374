import React, { FC, useEffect, useState } from 'react';
import { PopupStateless } from 'components/common';
import danglingPrepositions from 'utils/danglingPrepositions';
import LinkOpenInfo from '../../../UI/elements/LinkOpenInfo';
import PortalWrapper from '../../../../components/helpers/PortalWrapper';
import styles from './styles.pcss';
import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img5 from './assets/img5.png';
import preview from './assets/preview.png';
import imgContextual3 from './assets/imgContextual3.png';
import imgContextual2 from './assets/imgContextual2.png';
import imgContextual1 from './assets/imgContextual1.png';
import bigPreview from './assets/big_preview.jpg';

export const Wiki1 = () => {
  return (
    <div>
      <p className={styles.text}>Это показ таргетированной рекламы (баннеров или видео-роликов) в интернете определенной
        целевой аудитории, например, только мужчинам 25-50 лет из Москвы, которые интересуются активным отдыхом</p>
      <img src={img3} className={styles.img} alt="example"/>
      <p className={styles.text}>Таргетированная реклама отлично подходит для увеличения охвата, повышения узнаваемости
        бренда и привлечения новых клиентов</p>
      <WhiteBlock title="Про оплату"
        text="Оплата за кампании осуществляется по CPM (оплата за 1000 показов для баннеров и видео), СPV (оплата за просмотр для видео), по CPC (оплата за клики для баннеров)"/>
      {/* <WhiteBlock */}
      {/*  title="Как рассчитать количество показов по рекламной кампании?" */}
      {/*  text="Для бюджета 30000 руб с НДС количество показов для баннерной рекламы будет (30000/83*1000) = 316000 показов, для видео рекламы = (30000/330*1000) = 90000 показов" */}
      {/* /> */}
      <WhiteBlock title="Про частоту показов"
        text="Частота показа по умолчанию — 3, то есть за весь период проведения кампании каждый пользователь из аудитории увидит вашу рекламу не менее 3-х раз. Такая частота считается оптимальной для таргетированной рекламы для ее успешного запоминания"/>
    </div>
  );
};
export const Wiki2 = () => {
  return (
    <div>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <p className={styles.text}>
            Это формат рекламы, который интегрируется в контент социальной сети в виде поста таким образом, чтобы выглядеть естественно и не нарушать пользовательский опыт.
          </p>
        </li>
        <li className={styles.listItem}>
          <p className={styles.text}>
            Размещение возможно в популярных социальных сетях ВКонтакте и Одноклассники в открытых тематических группах и публичных страницах, а также в тематических каналах в Телеграм.
          </p>
        </li>
        <li className={styles.listItem}>
          <p className={styles.text}>
            Такая реклама позволяет охватить больше целевой аудитории и получить вирусное распространение своего рекламного предложения.
          </p>
        </li>
        <li className={styles.listItem}>
          <p className={styles.text}>
            Размещение постов подойдет для рекламы товаров и услуг, не ограниченных географией: сеть магазинов, онлайн услуги, доставка по всей России, туризм и мероприятия.
          </p>
        </li>
      </ul>
      {danglingPrepositions}
      {/* <p className={styles.text}>Это показ таргетированной рекламы (баннеров или видео-роликов) в соцсетях определенной
        целевой аудитории, например, только женщинам с детьми 25-45 лет из Новосибирска, которые интересуются покупкой
        детских товаров</p>
      <p className={styles.text}>Таргетированная реклама в соцсетях отлично подходит для увеличения охвата, повышения
        узнаваемости бренда и привлечения новых клиентов</p> */}
      <WhiteBlock
        title="Про оплату"
        text={
          danglingPrepositions(
            'Минимальная стоимость услуг составляет 10 000 ₽ с НДС. Размещения тарифицируются по модели CPM (цена за 1 000 показов). В минимальную стоимость входит 6 993 показа в каналах Телеграм или 23 256 в группах Вконтакте или Одноклассники.',
        )}
      />
    </div>
  );
};

export const Wiki3 = () => {
  return (
    <div>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <p className={styles.text}>Все рекламные кампании перед запуском в обязательном порядке проходят модерацию и проверку на соответствие
            требованиям к материалам и <a
              href="https://static.beeline.ru/upload/images/business/doc/redpolitika_beeline.pdf" rel="noreferrer"
              target="_blank" className={styles.link} style={{ display: 'inline', textDecoration: 'none' }}>редакционной
              политике</a>. Наш специалист свяжется с вами, если возникнут вопросы по вашей заявке </p>
        </li>
        <li className={styles.listItem}>
          Все рекламные материалы вы готовите сами
        </li>
      </ul>
    </div>
  );
};

export const Wiki4 = () => {
  return (
    <div>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          Заполните все обязательные поля брифа
        </li>
        <li className={styles.listItem}>
          Наш специалист получит вашу заявку и свяжется с вами для уточнения деталей и подтверждения заявки
        </li>
        <li className={styles.listItem}>Вы оплачиваете стоимость рекламной кампании через личный кабинет</li>
        <li className={styles.listItem}>После поступления денег и прохождения модерации рекламная кампания будет
          запущена
        </li>
        <li className={styles.listItem}>
          После окончания рекламной кампании вы получите отчет
        </li>
      </ul>
      <p className={styles.text}>Мы покажем вашу рекламу только реальным пользователям — активным абонентам билайн на
        сайтах и в мобильных приложениях, которые они посещают и гарантируем только целевые показы</p>
    </div>
  );
};

export const Wiki5 = () => {
  return (
    <div>
      <p className={styles.text}>По результатам завершенной рекламной кампании вы получите отчет с ключевыми
        показателями</p>
      <p className={styles.listText}>Состав отчета:</p>
      <ul className={styles.list}>
        <li className={styles.listItem}>Стоимость кампании, руб с НДС</li>
        <li className={styles.listItem}>Стоимость 1000 показов, руб с НДС</li>
        <li className={styles.listItem}>Стоимость 1 клика, руб с НДС</li>
        <li className={styles.listItem}>Стоимость 1 просмотра, руб с НДС</li>
        <li className={styles.listItem}>Кол-во показов</li>
        <li className={styles.listItem}>Кол-во кликов</li>
        <li className={styles.listItem}>Кол-во просмотров</li>
        <li className={styles.listItem}>Охват</li>
        <li className={styles.listItem}>Частота</li>
        <li className={styles.listItem}>CTR (клики)</li>
      </ul>
    </div>
  );
};

export const Wiki6 = () => {
  return (
    <div>
      <p className={styles.text}>билайн сотрудничает с крупнейшими поставщиками рекламного инвентаря в России, поэтому
        мы покажем вашу рекламу на тысячах самых популярных сайтов и в мобильных приложениях в рунете (только тем
        абонентам билайн, которые попали в аудиторию для показа)</p>
    </div>
  );
};

export const Wiki7 = () => {
  return (
    <div>
      <p className={styles.text}>Бюджет для запуска — от 300 000 ₽ с НДС</p>
    </div>
  );
};

export const Wiki8 = () => {
  return (
    <div>
      <ul className={styles.list}>
        <li className={styles.listItem}>Формат: GIF, JPG, JPEG, PNG</li>
        <li className={styles.listItem}>Размер изображения: не более 150 кб</li>
        <li className={styles.listItem}>Анимированные GIF-объявления должны воспроизводиться со скоростью не более 5
          кадров в секунду
        </li>
        <li className={styles.listItem}>Продолжительность анимации независимо от количества циклов не должна превышать
          30 секунд
        </li>
      </ul>
    </div>
  );
};

export const Wiki9 = () => {
  return (
    <div>
      <p className={styles.listText}>Для максимального охвата аудитории предоставьте баннеры следующих размеров:</p>
      <ul className={styles.list} style={{ marginTop: 0 }}>
        <li className={styles.listItem}>300*250</li>
        <li className={styles.listItem}>728*90</li>
        <li className={styles.listItem}>240*400</li>
        <li className={styles.listItem}>300*600</li>
        <li className={styles.listItem}>160*600</li>
        <li className={styles.listItem}>320*480</li>
        <li className={styles.listItem}>480*320</li>
      </ul>
      <img src={img1} className={styles.img} alt="example"/>
    </div>
  );
};

export const Wiki10 = () => {
  return (
    <div>
      <p className={styles.listText}>Для максимального охвата аудитории предоставьте баннеры следующих размеров:</p>
      <ul className={styles.list} style={{ marginTop: 0 }}>
        <li className={styles.listItem}>300*250</li>
        <li className={styles.listItem}>728*90</li>
        <li className={styles.listItem}>320*50</li>
        <li className={styles.listItem}>336*280</li>
        <li className={styles.listItem}>320*100</li>
      </ul>
      <img src={img2} className={styles.img} alt="example"/>
    </div>
  );
};

export const Wiki11 = () => {
  const [opened, setOpen] = useState(false);
  return (
    <>
      <div>
        <ul className={styles.list}>
          <li className={styles.listItem}>На баннере должен быть логотип или название вашей компании</li>
          <li className={styles.listItem}>Ваше предложение на баннере должно быть понятным и привлекательным</li>
          <li className={styles.listItem}>Получатели должны найти в тексте ответы на два вопроса: 1) Что вы предлагаете?
            2) Почему это выгодно?
          </li>
          <li className={styles.listItem}>Клиенты должны понимать, какие действия вы от них ожидаете, то есть в баннере
            должен быть очевидный призыв к действию: «Заказать», «Смотреть», «Перейти» и тп
          </li>
        </ul>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <p className={styles.link} onClick={() => setOpen(true)}>Посмотреть пример баннера</p>
      </div>
      <PopupStateless opened={opened} onClose={() => setOpen(false)}><img src={img5} alt="example"
        className={styles.modalImg}/></PopupStateless>
    </>
  );
};

export const Wiki12 = () => {
  return (
    <div>
      <ul className={styles.list}>
        <li className={styles.listItem}>Хронометраж ролика до 30 секунд. В случае превышения хронометража видео может
          быть заблокировано на площадках
        </li>
        <li className={styles.listItem}>Формат Mp4, кодек AAC</li>
        <li className={styles.listItem}>Пропорции желательно 16:9</li>
        <li className={styles.listItem}>Вес видеоролика — до 5 МБ</li>
      </ul>
    </div>
  );
};

export const Wiki13 = () => {
  return (
    <div>
      <ul className={styles.list}>
        <li className={styles.listItem}>На странице сайта рекламодателя не должны открываться дополнительные окна (PopUp
          или PopUnder)
        </li>
        <li className={styles.listItem}>Страница сайта должна корректно открываться в браузере и не содержать ошибок,
          скриптов и программ
        </li>
        <li className={styles.listItem}>Запрещена реклама ресурсов, основной деятельностью которых является заполнение
          анкет и тестов для получения денег или контактных данных
        </li>
        <li className={styles.listItem}>Если страница сайта, на которую ведет ссылка при размещении, или в ходе
          рекламной кампании не отвечает или открывается некорректно, размещение рекламных материалов будет
          приостановлено до исправления ошибки
        </li>
        <li className={styles.listItem}>Реклама должна соответствовать содержанию страницы, на которую ведет ссылка.
          Например, если рекламный материал содержит информацию о скидке, то и на посадочной странице скидка должна быть
          указана в явном виде
        </li>
      </ul>
    </div>
  );
};

export const Wiki14 = () => {
  return (
    <div>
      <ul className={styles.list}>
        <li className={styles.listItem}>Все загруженные вами рекламные материалы будут проверены нашими сотрудниками
          перед запуском рекламной кампании
        </li>
        <li className={styles.listItem}>Если ваш креатив не пройдет модерацию, наш специалист свяжется с вами для
          решения вопроса
        </li>
      </ul>
    </div>
  );
};

export const Wiki15 = () => {
  return (
    <div>
      <p className={styles.listText} style={{ marginTop: 0 }}>Материалы следующего содержания будут отклонены
        модератором:</p>
      <ul className={styles.list} style={{ marginTop: 0 }}>
        <li className={styles.listItem}>Табачные изделия</li>
        <li className={styles.listItem}>Алкоголь</li>
        <li className={styles.listItem}>Оружие</li>
        <li className={styles.listItem}>Лекарственные препараты, отпускаемые по рецепту и Бады</li>
        <li className={styles.listItem}>Азартные игры</li>
        <li className={styles.listItem}>Наркотические средства, психотропные вещества и способы их употребления</li>
        <li className={styles.listItem}>Нелегальное программное обеспечение: книги, фильмы, музыка</li>
        <li className={styles.listItem}>Продажа услуг по SMS</li>
        <li className={styles.listItem}>Поддельные документы</li>
        <li className={styles.listItem}>Порнография</li>
        <li className={styles.listItem}>Оборудование для слежки</li>
        <li className={styles.listItem}>Предсказания, астрологические прогнозы, колдовство, целительство и тп.</li>
        <li className={styles.listItem}>Поддельные товары</li>
        <li className={styles.listItem}>Искусственное прерывание беременности</li>
        <li className={styles.listItem}>Провокационные и оскорбительные материалы личного, политического или
          религиозного характера
        </li>
        <li className={styles.listItem}>Недобросовестная реклама</li>
        <li className={styles.listItem}>Побуждение к совершению противоправных действий</li>
        <li className={styles.listItem}>Политическая реклама</li>
        <li className={styles.listItem}>Связанные с трагическими событиями (убийство, теракт, смерть, пожар, взрыв и
          тп.)
        </li>
        <li className={styles.listItem}>Товары и услуги ритуального характера</li>
        <li className={styles.listItem}>Побуждение к совершению противоправных действий/призыв к насилию</li>
        <li className={styles.listItem}>Благотворительные фонды</li>
      </ul>
    </div>
  );
};

export const Wiki16 = () => {
  return (
    <div>
      <p className={styles.listText}>Перед загрузкой рекламных материалов рекомендуем ознакомиться с
        <a href="https://static.beeline.ru/upload/images/business/doc/redpolitika_beeline.pdf" rel="noreferrer"
          target="_blank" className={styles.link} style={{ display: 'inline', textDecoration: 'none' }}> редакционной
          политикой билайн</a>
      </p>
      <ul className={styles.list}>
        <li className={styles.listItem}>Допускается использование в рекламных материалах, наряду с текстом на русском
          языке, текста на иностранном языке при условии, что данный текст идентичен по своему содержанию и техническому
          оформлению текста на русском языке. Любые иностранные слова в тексте должны быть переведены или
          транслитерированы (за исключением товарных знаков)
        </li>
        <li className={styles.listItem}>Стоимостные показатели должны быть указаны в рублях</li>
        <li className={styles.listItem}>Если в рекламных материалах используются прилагательные в превосходной степени,
          или содержатся сведения о преимуществах рекламируемого товара перед находящимися в обороте товарами, то на
          странице сайта рекламодателя данная информация должна быть подтверждена независимыми исследованиями
        </li>
        <li className={styles.listItem}>билайн не осуществляет распространение информации, которая не является рекламой
          в соответствии с действующим законодательством о рекламе (ФЗ №38 — ФЗ «О рекламе»)
        </li>
        <li className={styles.listItem}>Реклама должна соответствовать требованиям ФЗ от 29.12.2010 г. №436 — ФЗ «О
          защите детей от информации, причиняющей вред их здоровью и развитию» в случае необходимости маркироваться
          знаками информационной продукции
        </li>
        <li className={styles.listItem}>Если в рекламных материалах используются товарные знаки, на такое использование
          должно быть соответствующее письменное разрешение от правообладателя
        </li>
        <li className={styles.listItem}>билайн вправе отказать в обеспечении распространения рекламы товаров и услуг
          третьих лиц, конкурирующих с товарами и услугами билайн. Также возможны дополнительные ограничения по
          тематикам, которые согласуются сотрудниками билайн в соответствии с процедурой согласования рекламных кампаний
        </li>
        <li className={styles.listItem}>Для ряда отраслей, согласно ФЗ №38 — ФЗ «О рекламе», необходимо включить в
          рекламный материал дисклеймер с указанием сведения о продавце таких товаров:
        </li>
        <ul className={styles.list}>
          <li className={styles.listItem}>Реклама медицинских услуг, лекарств, методов лечения, косметологии,
            ветеринарии, оптики требует указаний о противопоказаниях и консультации со специалистом.
            Например: «Имеются противопоказания, требуется консультация специалиста»
          </li>
          <li className={styles.listItem}>Реклама страхования, банковских продуктов, финансовых организаций — нужно
            указывать наименование или имя лица, оказывающего данные услуги (для юридического лица — наименование, для
            индивидуального предпринимателя — фамилию, имя, отчество
          </li>
          <li className={styles.listItem}>Реклама от застройщиков — сведения о месте и способах получения проектной
            декларации, а также иной подробной информации, и наименование застройщика.
            Например: «Проектная декларация наш.дом.рф»
          </li>
          <li className={styles.listItem}>Реклама товаров/услуг при дистанционном способе их продажи,
            интернет-магазинов: наименование юридического лица, ИНН и место его нахождения. Для ИП - ФИО и ОГРНИП или
            ОГРН Например:«ООО Наименование» ИНН 335528473698, г.Москва, ул.Лесная 9». «ИП Иванов Иван Иванович, ОГРНИП
            333030303030303, г.Пермь, ул.Морская 5»
          </li>
          <li className={styles.listItem}>Реклама информационной продукции: приложений, сайтов, программ для ЭВМ,
            онлайн-игр, зрелищных и культурно-массовых мероприятий, анонс фильмов и мультфильмов, фестивалей, концертов,
            выставок, ярмарок и пр.— необходимо указать знак информационной продукции, примеры обозначений: возрастное
            ограничение: 0+, 6+, 12+, 16+, 18+
          </li>
          <li className={styles.listItem}>Реклама Форекс-дилеры, инвестиции должна содержать следующее указание:
            «Предлагаемые к заключению договоры или финансовые инструменты являются высокорискованными и могут привести
            к потере внесенных денежных средств в полном объеме. До совершения сделок следует ознакомиться с рисками, с
            которыми они связаны»
          </li>
          <li className={styles.listItem}>Центры по обучению и повышению квалификации обязаны указать полное
            наименование включая организационно правовую форму + N лицензии в поле дополнительной юридической информации
          </li>
        </ul>
      </ul>
    </div>
  );
};

export const Wiki17 = () => {
  return (
    <div>
      <p className={styles.text}>Если вы выбрали для рекламы аудиторию билайн в реальном времени, то мы покажем рекламу
        пользователю уже в течение 15 минут после проявления его интереса к определенному сайту, что существенно
        повышает вероятность интереса к вашей рекламе</p>
      <WhiteBlock title="Пример"
        text="Для рекламной кампании вам нужны абоненты билайн, которые могут интересоваться сайтами автопроизводителей прямо сейчас. Мы покажем вашу рекламу таким пользователем в течение 15 минут после проявления интереса"/>
      <p className={styles.text}>Для запуска рекламы на аудиторию билайн в реальном времени по сайтам, укажите в поле
        описания аудитории интересующие сайты в любом формате, не менее 5 и не более 100 штук</p>
    </div>
  );
};

export const Wiki18 = () => {
  return (
    <div>
      <p className={styles.text}>билайн не осуществляет анализ посещенных сайтов, поисковых запросов, телефонных вызовов
        и SMS-сообщений пользователей. Предоставленная заказчиками услуги информация используется исключительно для
        составления портрета целевой аудитории, на основании которого впоследствии осуществляется поиск потенциальных
        клиентов для заказчиков среди пользователей</p>
    </div>
  );
};

export const Wiki19 = () => {
  return (
    <div>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          Заполните все обязательные поля брифа
        </li>
        <li className={styles.listItem}>
          Наш специалист получит вашу заявку, рассчитает медиаплан в соответствии с вашим бюджетом и свяжется с вами для
          его утверждения
        </li>
        <li className={styles.listItem}>Вы оплачиваете стоимость рекламной кампании через личный кабинет</li>
        <li className={styles.listItem}>После поступления денег и прохождения модерации рекламная кампания будет
          запущена
        </li>
        <li className={styles.listItem}>
          После окончания рекламной кампании вы получите отчет
        </li>
      </ul>
      <p className={styles.text}>Мы покажем вашу рекламу только реальным пользователям — активным абонентам билайн в
        соцсетях и гарантируем только целевые показы</p>
    </div>
  );
};

export const Wiki20 = () => {
  return (
    <div>
      <p className={styles.text}>билайн сотрудничает с крупнейшими российскими соцсетями и площадками: MyTarget,
        Вконтакте, Одноклассники и покажет ваш баннер или видео-ролик только тем абонентам билайн, которые попали в
        аудиторию для показа</p>
    </div>
  );
};

export const Wiki21 = () => {
  return (
    <div>
      <p className={styles.text} style={{ marginTop: 0 }}>Бюджет для запуска — от 30 000 ₽ с НДС </p>
      <div><a href="https://static.beeline.ru/upload/images/marketing/price_internet3.pdf" target="_blank"
        rel="noreferrer" className={styles.link}>Смотреть прайс</a></div>
      <p className={styles.text}>Предложение по форматам, требования к рекламным материалам (если они еще не готовы) и
        целевые показатели вы получите в медиаплане от нашего специалиста</p>
    </div>
  );
};

export const Wiki22 = () => {
  return (
    <div>
      <p className={styles.text}>В ссылке должна быть указана собственная целевая страница рекламодателя (рекламируемый
        сайт). Редиректы (перенаправление) на другие страницы, в том числе мета-редиректы и трафикбэк, запрещены. Также
        запрещены не серверные редиректы.</p>
      <ul className={styles.list}>
        <li className={styles.listItem}>Ссылка не должна вести:</li>
        <ul className={styles.list}>
          <li className={styles.listItem}>на стороннюю социальную сеть (исключая OK и Vk)</li>
          <li className={styles.listItem}>на закрытое сообщество/группу</li>
          <li className={styles.listItem}>на адрес электронной почты, или файл (включая изображения, аудио- или
            видеозаписи и документы, которые можно запустить только с помощью дополнительных приложений)
          </li>
        </ul>
        <li className={styles.listItem}>В случае, если ссылка ведет на сайт, составленный на языке, отличном от языка,
          на котором составлено объявление, в тексте объявления необходима информация об этом
        </li>
        <li className={styles.listItem}>Для рекламы мобильных приложений в url допускаются только безопасные ссылки. Все
          редиректы и все лендинги должны быть https://
        </li>
        <li className={styles.listItem}>Не забудьте указать UTM-метку, чтобы отслеживать аналитику по кампании в Google
          analytics и Yandex Метрика
        </li>
      </ul>
    </div>
  );
};


export const Wiki23 = () => {
  return (
    <div>
      <p className={styles.text}>Ознакомиться с требованиями к материалам можно <a href=""
        className={styles.link}>тут</a>
      </p>
      <p className={styles.text}>Если материалы у вас уже готовы и вы точно знаете какой формат будете запускать, то
        материалы можно сразу залить на файлообменник и вставить ссылку в соответствующее поле «Рекламные материалы»</p>
    </div>
  );
};

export const Wiki24 = () => {
  return (
    <div>
      <p className={styles.text}>
        Это текстовые, графические или иные рекламные объявления, которые показываются пользователям, если они вводят
        поисковый запрос,
        например, «Купить автомобиль» или «Где найти дешевый тур» или интересуются определенной тематикой в интернете
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '170px' }}>
          <img src={imgContextual1} className={styles.img2} alt="example"/>
          <span style={{ fontSize: '16px', fontFamily: 'TT Interfaces' }}>В поиске</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '170px' }}>
          <img src={imgContextual2} className={styles.img2} alt="example"/>
          <span style={{ fontSize: '16px', fontFamily: 'TT Interfaces' }}>В сети</span>
        </div>
      </div>
      <WhiteBlock title="Про оплату"
        text="Оплата за кампании осуществляется по CPC (оплата за клики по рекламным объявлениям)"/>
    </div>
  );
};


export const Wiki25 = () => {
  return (
    <div>
      <ul className={styles.list}>
        <li className={styles.listItem}>Заполните все обязательные поля брифа</li>
        <li className={styles.listItem}>Наш специалист получит вашу заявку и свяжется с вами для уточнения деталей и
          подтверждения заявки, а также озвучит прогноз по количеству и стоимости кликов.
        </li>
        <li className={styles.listItem}>Вы оплачиваете стоимость рекламной кампании через личный кабинет</li>
        <li className={styles.listItem}>После поступления денег и прохождения модерации рекламная кампания будет
          запущена
        </li>
        <li className={styles.listItem}>После окончания рекламной кампании вы получите отчет</li>
      </ul>
    </div>
  );
};


export const Wiki26 = () => {
  return (
    <div>
      <p className={styles.text}>
        Для подбора аудитории мы используем:
      </p>
      <ul className={styles.list}>
        <li className={styles.listItem}>аудиторию из абонентов билайн, собранную по вашему запросу</li>
        <li className={styles.listItem}>ключевые слова по вашей тематике</li>
      </ul>
    </div>
  );
};

export const Wiki27 = () => {
  return (
    // <div>
    //   <p className={styles.text}>Мы покажем ваши рекламные объявления при вводе нужного запроса в поисковике Яндекс1</p>
    //   <img src={imgContextual2} className={styles.img} alt="example" />
    // </div>
    <div>
      <p className={styles.text}>
        Мы покажем ваши рекламные объявления при вводе нужного запроса в поисковике Яндекс
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '170px' }}>
          <img src={imgContextual1} className={styles.img2} alt="example"/>
          <span style={{ fontSize: '16px', fontFamily: 'TT Interfaces' }}>В поиске</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '170px' }}>
          <img src={imgContextual2} className={styles.img2} alt="example"/>
          <span style={{ fontSize: '16px', fontFamily: 'TT Interfaces' }}>В сети</span>
        </div>
      </div>
      {/* <WhiteBlock title="Про оплату" text="Оплата за кампании осуществляется по CPC (оплата за клики по рекламным объявлениям)"/> */}
    </div>
  );
};

export const Wiki28 = () => {
  return (
    <div>
      <p className={styles.text}>По результатам завершенной рекламной кампании вы получите отчет с ключевыми
        показателями</p>
      <ul className={styles.list1}>
        <p className={styles.listItem}>Состав отчета:</p>
        <ul className={styles.list}>
          <li className={styles.listItem}>Итоговая стоимость кампании</li>
          <li className={styles.listItem}>Количество показов</li>
          <li className={styles.listItem}>Количество кликов</li>
          <li className={styles.listItem}>Конверсия в клики (CTR)</li>
          <li className={styles.listItem}>Стоимость клика</li>
        </ul>
      </ul>
    </div>
  );
};

export const Wiki29 = () => {
  return (
    <div>
      <p className={styles.text}>Бюджет для запуска — от 30 000 ₽ с НДС</p>
    </div>
  );
};

export const Wiki30 = () => {
  return (
    <div>
      <p className={styles.text}>
        билайн не осуществляет анализ посещенных сайтов, поисковых запросов, телефонных вызовов и SMS-сообщений
        пользователей. Предоставленная заказчиками услуги информация используется исключительно для составления портрета
        целевой аудитории, на основании которого впоследствии осуществляется поиск потенциальных клиентов для заказчиков
        среди пользователей </p>
    </div>
  );
};


export const Wiki31 = () => {
  const [showSendDocs, setShowSendDocs] = useState<boolean>(false);
  const [selectedFileId, setSelectedFileId] = useState<null | number>(null);

  const popup = () => {
    const onCancel = () => {
      setShowSendDocs(false);
      setSelectedFileId(null);
    };

    const onOk = () => {
      setShowSendDocs(false);
      setSelectedFileId(null);
    };

    return (
      <PopupStateless
        opened
        className={styles.modal}
        onClose={onCancel}>
        <div className={styles.modalTitle}>Как подготовить текст объявления</div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <ul style={{ textAlign: 'start' }}>
              <li className={styles.listItem}>
                Благодаря вхождению ключевого <br/> слова в заголовок и быструю ссылку,<br/> текст подсвечивается жирным<br/> шрифтом
              </li>
              <li className={styles.listItem}>
                Указаны слова-стимуляторы:<br/> «Проверьте», «Купить»
              </li>
            </ul>
            <ul style={{ textAlign: 'start' }}>
              <li className={styles.listItem}>
                Указаны конкурентные<br/> преимущества: быстрый 4G, широкое<br/> покрытие, быстрый интернет
              </li>
              <li className={styles.listItem}>
                Используются дополнения: быстрые<br/> ссылки, телефон, время работы
              </li>
            </ul>
          </div>
          <div style={{ marginTop: '30px' }}>
            <img src={imgContextual3} className={styles.img2} alt="example"/>
          </div>
        </div>
        {/* <PrimaryButton */}
        {/*   className={styles.sendModalBtn} */}
        {/*   onClick={onOk} */}
        {/* > */}
        {/*   Да */}
        {/* </PrimaryButton> */}
        {/* <PrimaryButton */}
        {/*   className={styles.sendModalBtnCancel} */}
        {/*   onClick={onCancel} */}
        {/* > */}
        {/*   Отмена */}
        {/* </PrimaryButton> */}
      </PopupStateless>
    );
  };

  return (
    <div>
      {showSendDocs && popup()}
      <ul className={styles.list}>
        <li className={styles.listItem}>Текст должен отражать суть вашего рекламного объявления. Ваше предложение должно
          быть понятным и привлекательным
        </li>
        <li className={styles.listItem}>Пользователи должны найти в тексте ответы на три вопроса: 1) Что вы предлагаете?
          2) Почему это выгодно? 3) Как с вами связаться?
        </li>
        <li className={styles.listItem}>Клиенты должны понимать, какие действия вы от них ожидаете, то есть должен быть
          очевидный призыв к действию: «Заказать», «Смотреть», «Перейти» и тп
        </li>
      </ul>
      <span className={styles.listItem} onClick={() => setShowSendDocs(true)}
        style={{ cursor: 'pointer', color: '#9315B3', textDecoration: 'underline' }}>Смотреть пример</span>
    </div>
  );
};


export const Wiki32 = () => {
  return (
    <div>
      <ul>
        <li className={styles.listItem}>Ознакомьтесь с требованиями к модерации площадки Яндекс
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://yandex.ru/support/direct/moderation/adv-rules.html" target="_blank"
            style={{ textDecoration: 'underline', overflowWrap: 'break-word', cursor: 'pointer', color: '#9315B3' }}>
            &nbsp; https://yandex.ru/support/direct/moderation/adv-rules.html</a>
        </li>
        <li className={styles.listItem}>Все загруженные вами рекламные материалы будут проверены нашими сотрудниками
          перед запуском рекламной кампании
        </li>
        <li className={styles.listItem}>Если ваш креатив не пройдет модерацию, наш специалист свяжется с вами для
          решения вопроса
        </li>
      </ul>
    </div>
  );
};


export const Wiki33 = () => {
  return (
    <div>
      <p className={styles.text}>Материалы следующего содержания будут отклонены модератором:</p>
      <ul>
        <li className={styles.listItem}>Табачные изделия</li>
        <li className={styles.listItem}>Алкоголь</li>
        <li className={styles.listItem}>Оружие</li>
        <li className={styles.listItem}>Лекарственные препараты, отпускаемые по рецепту и Бады</li>
        <li className={styles.listItem}>Азартные игры</li>
        <li className={styles.listItem}>Наркотические средства, психотропные вещества и способы их употребления</li>
        <li className={styles.listItem}>Нелегальное программное обеспечение: книги, фильмы, музыка</li>
        <li className={styles.listItem}>Продажа услуг по SMS</li>
        <li className={styles.listItem}>Поддельные документы</li>
        <li className={styles.listItem}>Порнография</li>
        <li className={styles.listItem}>Оборудование для слежки</li>
        <li className={styles.listItem}>Предсказания, астрологические прогнозы, колдовство, целительство и тп.</li>
        <li className={styles.listItem}>Поддельные товары</li>
        <li className={styles.listItem}>Искусственное прерывание беременности</li>
        <li className={styles.listItem}>Провокационные и оскорбительные материалы личного, политического или
          религиозного характера
        </li>
        <li className={styles.listItem}>Недобросовестная реклама</li>
        <li className={styles.listItem}>Побуждение к совершению противоправных действий</li>
        <li className={styles.listItem}>Политическая реклама</li>
        <li className={styles.listItem}>Связанные с трагическими событиями (убийство, теракт, смерть, пожар, взрыв и
          тп.)
        </li>
        <li className={styles.listItem}>Товары и услуги ритуального характера</li>
        <li className={styles.listItem}>Побуждение к совершению противоправных действий/призыв к насилию</li>
        <li className={styles.listItem}>Благотворительные фонды</li>
      </ul>
    </div>
  );
};


export const Wiki34 = () => {
  return (
    <div>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <p className={styles.text}>Перед загрузкой рекламных материалов рекомендуем ознакомиться с <a
        href="https://static.beeline.ru/upload/images/business/doc/redpolitika_beeline.pdf" target="_blank"
        rel="noreferrer"
        style={{ color: '#9315B3', cursor: 'pointer' }}>редакционной политикой билайн</a></p>
      <ul>
        <li className={styles.listItem}>
          Допускается использование в рекламных материалах, наряду с текстом на русском языке,
          текста на иностранном языке при условии, что данный текст идентичен по своему содержанию и техническому
          оформлению тексту на русском языке.
          Любые иностранные слова в тексте должны быть переведены или транслитерированы (за исключением товарных знаков)
        </li>
        <li className={styles.listItem}>Стоимостные показатели должны быть указаны в рублях</li>
        <li className={styles.listItem}>
          Если в рекламных материалах используются прилагательные в превосходной степени, или содержатся сведения о
          преимуществах рекламируемого товара перед находящимися в обороте товарами, то на странице сайта рекламодателя
          данная информация должна быть подтверждена независимыми исследованиями
        </li>
        <li className={styles.listItem}>
          билайн не осуществляет распространение информации, которая не является рекламой в соответствии с действующим
          законодательством о рекламе (ФЗ №38 — ФЗ «О рекламе»)
        </li>
        <li className={styles.listItem}>
          Реклама должна соответствовать требованиям ФЗ от 29.12.2010 г. №436 — ФЗ «О защите детей от информации,
          причиняющей вред их здоровью и развитию» в случае необходимости маркироваться знаками информационной продукции
        </li>
        <li className={styles.listItem}>
          Если в рекламных материалах используются товарные знаки, на такое использование должно быть соответствующее
          письменное разрешение от правообладателя
        </li>
        <li className={styles.listItem}>
          билайн вправе отказать в обеспечении распространения рекламы товаров и услуг третьих лиц, конкурирующих с
          товарами и услугами билайн. Также возможны дополнительные ограничения по тематикам, которые согласуются
          сотрудниками билайн в соответствии с процедурой согласования рекламных кампаний
        </li>
        <li className={styles.listItem}>
          Для ряда отраслей, согласно ФЗ №38 — ФЗ «О рекламе», необходимо включить в рекламный материал дисклеймер с
          указанием сведения о продавце таких товаров:
          <ul>
            <li className={styles.listItem}>
              Реклама медицинских услуг, лекарств, методов лечения, косметологии, ветеринарии, оптики требует указаний о
              противопоказаниях и консультации со специалистом.
            </li>
            <li className={styles.listItem}>
              Например:
              «Имеются противопоказания, требуется консультация специалиста»
            </li>
            <li className={styles.listItem}>
              Реклама страхования, банковских продуктов, финансовых организаций — нужно указывать наименование или имя
              лица, оказывающего данные услуги (для юридического лица — наименование, для индивидуального
              предпринимателя — фамилию, имя, отчество)
            </li>
            <li className={styles.listItem}>
              Реклама от застройщиков — сведения о месте и способах получения проектной декларации, а также иной
              подробной информации, и наименование застройщика.
            </li>
            <li className={styles.listItem}>
              Например:
              «Проектная декларация наш.дом.рф»
            </li>
            <li className={styles.listItem}>
              Реклама товаров/услуг при дистанционном способе их продажи, интернет-магазинов: для наименование
              юридического лица, ИНН и место его нахождения. Для ИП - ФИО и ОГРНИП или ОГРН
              Например:
              «ООО Наименование» ИНН 335528473698, г.Москва, ул. Лесная 9». «ИП Иванов Иван Иванович, ОГРНИП
              333030303030303, г. Пермь, ул. Морская 5»
            </li>
            <li className={styles.listItem}>
              Реклама информационной продукции: приложений, сайтов, программ для ЭВМ, онлайн-игр, зрелищных и
              культурно-массовых мероприятий, анонс фильмов и мультфильмов, фестивалей, концертов, выставок, ярмарок и
              пр.— необходимо указать знак информационной продукции, примеры обозначений: возрастное ограничение: 0+,
              6+, 12+, 16+, 18+
            </li>
            <li className={styles.listItem}>
              Реклама Форекс-дилеры, инвестиции должна содержать следующее указание: «Предлагаемые к заключению договоры
              или финансовые инструменты являются высокорискованными и могут привести к потере внесенных денежных
              средств в полном объеме. До совершения сделок следует ознакомиться с рисками, с которыми они связаны»
            </li>
            <li className={styles.listItem}>
              Центры по обучению и повышению квалификации обязаны указать полное наименование включая организационно
              правовую форму + N лицензии в поле дополнительной юридической информации
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export const Wiki35 = () => {
  return (
    <div>
      <p className={styles.text}>Мы подберем нужную аудиторию среди абонентов билайн за определенный прошлый период (за
        месяц, неделю и тп.)</p>
      <WhiteBlock title="Пример"
        text="Для рекламной кампании вам нужны абоненты билайн, которые могли интересоваться сайтами автопроизводителей на прошлой неделе"/>
    </div>
  );
};

export const Wiki36 = () => {
  return (
    <div>
      <p className={styles.text}>Мы подберем нужную аудиторию среди абонентов билайн, которые могут интересоваться
        определенными сайтами прямо сейчас</p>
      <WhiteBlock title="Пример"
        text="Для рекламной кампании вам нужны абоненты билайн из Москвы, которые могут интересоваться сайтами автопроизводителей прямо сейчас. Мы покажем вашу рекламу (баннер или видео) таким пользователем уже в течение 15 минут после проявления интереса. По данным билайн, при запуске рекламы в реальном времени конверсия в клик в среднем в 2-3 раза выше, чем при запуске обычной рекламы"/>
      <p className={styles.text}>Для запуска рекламы на аудиторию билайн в реальном времени по сайтам, укажите в поле
        описания аудитории интересующие сайты в любом формате, не менее 5 и не более 100 штук</p>
    </div>
  );
};

export const Wiki37 = () => {
  return (
    <div>
      <p className={styles.text}>Также для интернет-рекламы мы можем собрать для вас аудиторию, которая будет наиболее
        похожа на ваших текущих клиентов (look-a-like аудитория). Для этого просто загрузите вашу клиентскую базу в
        разделе «Мои аудитории» и мы соберем для вашей рекламной кампании похожих пользователей, используя более 100+
        параметров аудитории</p>
    </div>
  );
};

export const Wiki38 = () => {
  return (
    <div>
      <p className={styles.text}>Что такое ключевые слова? Это слова, относящиеся к тематике вашего бизнеса, которые
        употребляются на различных сайтах в рунете</p>
      <WhiteBlock title="Пример"
        text="Ваш бизнес работает в сфере недвижимости. Ключевыми словами для вашей отрасли могут быть: покупка квартиры, ипотека, двушка, ремонт и тп. Мы найдем сайты в рунете, где употребляются эти слова, соберем аудиторию абонентов билайн, которые могли посещать данные сайты за определенный период или в реальном времени и покажем вашу рекламу (баннеры или видео) именно этим пользователям"/>
    </div>
  );
};

export const Wiki39 = () => {
  return (
    <div>
      <p className={styles.text}>билайн не осуществляет анализ посещенных сайтов, поисковых запросов, телефонных вызовов
        и SMS-сообщений пользователей. Предоставленная заказчиками услуги информация используется исключительно для
        составления портрета целевой аудитории, на основании которого впоследствии осуществляется поиск потенциальных
        клиентов для заказчиков среди пользователей</p>
    </div>
  );
};

export const Wiki40 = () => {
  return (
    <div>
      <p className={styles.text}>
        Шаблон сообщения - это заранее заготовленный и согласованный модераторами билайн текст рекламного
        сообщения.<br/>
        Шаблоны доступны только для рекламы по SMS.<br/>
        Шаблоны используются для экономии времени на запуск повторяющихся рекламных кампаний. При использовании
        согласованного шаблона в рекламной рассылке пропускается этап согласования текста сообщения.
      </p>
    </div>
  );
};


const WhiteBlock = ({ title, text }: { title?: string, text?: string | string[] }) => {
  return (
    <div className={styles.whiteBlock}>
      {
        title &&
        <span className={styles.title}>{title}</span>
      }

      {
        text && typeof text === 'string' &&
        <p className={styles.textInWhite}>
          {text}
        </p>
      }

      {
        text && Array.isArray(text) &&
        text.map(item => {
          return (
            <p className={styles.textInWhite}>
              {item}
            </p>
          );
        })
      }
    </div>
  );
};

export const Wiki41 = () => {
  return (
    <div>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          Чтобы настроить вашу рекламную кампанию для пользователей, необходимо знать, кто ваша целевая аудитория - пол,
          возраст, доход, интересы, геолокация.
          С помощью портрета аудитории вы узнаете, где живут ваши клиенты, чем они интересуются, где отдыхают, тип
          занятости, наличие собственности, интернет-активность и даже сможете получить тепловую карту - концентрацию
          ваших клиентов в определенных геолокациях.
        </li>
        <li className={styles.listItem}>
          Чтобы заказать отчет, достаточно просто загрузить вашу базу клиентов, при условии, что в ней содержится не
          менее 100 телефонных номеров абонентов билайн. Стандартные отчеты “бесплатный”, “оптимальный” и “максимальный”
          формируются автоматически в течение пары минут.
          Готовый отчет вы можете скачать в разделе “Мои отчеты”
        </li>
      </ul>
    </div>
  );
};

export const Wiki42 = () => {
  return (
    <div style={{ marginTop: '20px' }}>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          1 сентября 2022 года в России вступил в силу закон № 347-ФЗ о поправках в Федеральный закон «О рекламе».
          По новым правилам основные форматы интернет-рекламы в рунете нужно будет маркировать и передавать информацию о
          рекламных кампаниях в Единый реестр интернет-рекламы (ЕРИР).
        </li>
        <li className={styles.listItem}>
          Операторы рекламных данных (ОРД) помогут упростить маркировку и передачу данных в ЕРИР.
          Они будут агрегировать информацию от всех участников рынка и присваивать токены рекламным креативам.
        </li>
      </ul>
      <WhiteBlock title="Про комиссию"
        text="билайн.ПРОдвижение соблюдает закон о рекламе и может бесплатно подать данные о вашей рекламной кампании в ОРД"/>
    </div>
  );
};

export const Wiki43 = () => {
  return (
    <div style={{ marginTop: '20px' }}>
      <p>Разметка, или маркировка, рекламы включает основные действия:</p>
      <ol className={styles.listOrd} style={{ listStylePosition: 'inside' }}>
        <li className={styles.listItem}>
          Подачу информации о конечном заказчике перед размещением рекламы
        </li>
        <li className={styles.listItem}>
          Получение токена (уникального идентификатора рекламного объявления, присваиваемого оператором рекламных данных
          для каждого креатива)
        </li>
        <li className={styles.listItem}>
          Добавление пометки «Реклама» ко всем креативам
        </li>
      </ol>
    </div>
  );
};

export const Wiki44 = () => {
  return (
    <div>
      <p>
        Это выборка аудитории по вашим индивидуальным параметрам, подготовленная с помощью команды аналитиков билайн.
        Сбор аудитории занимает до 4 рабочих дней, в зависимости от сложности и периода для запроса. Мы напишем вам на
        почту, когда аудитория будет готова.
        С этой выборкой вы сможете провести любое количество рекламных кампаний в каналах SMS и PUSH. Готовую аудиторию
        ищите в разделе «Мои аудитории» по названию.
        Чтобы отправить запрос, необходимо внести предоплату в размере 30 000 ₽ с НДС. Эта сумма станет авансом для
        ваших будущих рекламных кампаний с использованием этой аудитории.
        Например, вы собрали индивидуальную аудиторию и хотите разослать 6000 рекламных сообщений. Тогда ваш расчет
        будет выглядеть следующим образом:
        длина SMS 134 символа (две части) — 5,00 ₽, индивидуальный подбор аудитории — (+5,50 ₽). Итого, стоимость смс с
        учетом индивидуальных таргетов — 7,50 ₽. Умножаем на 6000 сообщений, получаем бюджет вашей рекламной кампании 45
        000 ₽. С учетом предоплаты 30 000 ₽ вам останется доплатить 15 000 ₽.
        Вы можете потратить предоплату сразу за одну рекламную кампанию, либо использовать для нескольких рассылок.
        Предоплата не сгорает, остаток будет сохраняться на вашем счету, но не может быть использован для рассылок в
        других каналах и на другие сегменты аудитории.
      </p>
    </div>
  );
};

export const Wiki45 = () => {
  return (
    <div>
      <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
        <li>1 способ – Найти эту аудиторию в разделе «Мои аудитории». Нажатием на действие «Запустить кампанию» перейти
          к оформлению заявки.
        </li>
        <li>2 способ – Перейти в оформление заявки на SMS или PUSH. Выбрать тип аудитории «По индивидуальному запросу»,
          выбрать сохраненную аудиторию и далее продолжить оформление.
        </li>
        <li>3 способ - Найти эту аудиторию в разделе «Мои аудитории». Перейти на страницу подробного просмотра
          аудитории. Нажатием на кнопку «Запустить кампанию» перейти к оформлению заявки.
        </li>
      </ul>
    </div>
  );
};

export const Wiki46Soc = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>
          Реклама в социальных сетях – формат рекламы, который интегрируется в контент социальной сети в виде поста таким образом, чтобы выглядеть естественно и не нарушать пользовательский опыт.
        </p>
        <p className={styles.textInWhite}>
          Размещение возможно в популярных социальных сетях ВКонтакте и Одноклассники в открытых тематических группах и публичных страницах, а также в тематических каналах в Телеграм.
        </p>
        <p className={styles.textInWhite}>
          Такая реклама позволяет охватить больше целевой аудитории и получить вирусное распространение своего рекламного предложения.
        </p>
        <p className={styles.textInWhite}>
          Размещение постов подойдет для рекламы товаров и услуг, не ограниченных географией: сеть магазинов, онлайн услуги, доставка по всей России, туризм и мероприятия.
        </p>
      </div>
    </>
  );
};

export const Wiki46 = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>
          Цифровая наружная реклама (DOOH, Digital out-of-home) – формат рекламы <br/>на билбордах, ситибордах,
          суперсайтах<br/> и ситиформатах в Москве, Санкт-Петербурге и других городах России
        </p>
        <p className={styles.textInWhite}>
          Ваша реклама будет размещена вдоль автомобильных трасс, рядом<br/> с железнодорожными вокзалами, аэропортами и
          торговыми центрами</p>
      </div>
    </>
  );
};

export const Wiki46Aud = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>
          Аудиореклама в магазине – это короткие ролики, транслируемые по радио внутри магазина
        </p>
        <p className={styles.textInWhite}>
          Посредством этих роликов можно оповестить потенциальных клиентов о скидках или акциях, особых предложениях или
          новой продукции
        </p>
        <p className={styles.textInWhite}>
          А еще аудиореклама является средством навигации и помогает покупателям ориентироваться в здании
        </p>
      </div>
    </>
  );
};

export const Wiki47Aud = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>
          Мы разместим вашу рекламу в некоторых магазинах Дикси
        </p>
        <p className={styles.textInWhite}>
          Рекламу услышит аудитория, которая находится в момент звучания ролика в самом магазине
        </p>
      </div>
    </>
  );
};

export const Wiki48Aud = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>
          Ваш ролик будет звучать 4 раза в час, каждый день на протяжении 30 дней, в рабочие часы магазина
        </p>
      </div>
    </>
  );
};


export const Wiki49Aud = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>
          Бюджет для запуска — от 10 000 ₽ с НДС
        </p>
        <p className={styles.textInWhite}>
          Размещение роликов в магазинах платное и зависит от длины ролика:
        </p>
        <ol style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>10000 ₽ с НДС 30 дней 1 магазин, 10 сек ролик</p></li>
          <li><p className={styles.textInWhite}>14850 ₽ с НДС 30 дней 1 магазин, 15 сек ролик (самый популярный
            вариант)</p></li>
          <li><p className={styles.textInWhite}>19800 ₽ с НДС 30 дней 1 магазин, 20 сек ролик</p></li>
          <li><p className={styles.textInWhite}>29700 ₽ с НДС 30 дней 1 магазин, 30 сек ролик</p></li>
        </ol>
        <p className={styles.textInWhite}>
          Если у вас нет готового ролика, мы можем создать его бесплатно
        </p>
      </div>
    </>
  );
};

export const Wiki50Aud = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Порядок запуска:</span>
        <ol style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Заполните все обязательные поля брифа</p></li>
          <li><p className={styles.textInWhite}>Наш специалист получит вашу заявку и свяжется с вами для уточнения
            деталей и подтверждения заявки</p></li>
          <li><p className={styles.textInWhite}>Вы оплачиваете стоимость рекламной кампании через личный кабинет</p>
          </li>
          <li><p className={styles.textInWhite}>После поступления денежных средств и прохождения модерации рекламная
            кампания будет запущена</p></li>
          <li><p className={styles.textInWhite}>После окончания рекламной кампании вы получите отчет</p></li>
        </ol>
      </div>
    </>
  );
};

export const Wiki51Aud = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>Если у вас собственный аудиоролик, сверьтесь с нашими требованиями:</p>
        <ul style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Форматы файла: MP3</p></li>
          <li><p className={styles.textInWhite}>Частота: 44100 Hz</p></li>
          <li><p className={styles.textInWhite}>Глубина: 16 bit</p></li>
          <li><p className={styles.textInWhite}>Битрейт: не менее 256 kbps</p></li>
          <li><p className={styles.textInWhite}>Средняя громкость аудиоролика должна быть в диапазоне -12,0 dB до -12,5
            dB</p></li>
          <li><p className={styles.textInWhite}>Пиковый уровень лимитирован на -3 dB</p></li>
          <li><p className={styles.textInWhite}>Для роликов, в которых присутствует ТОЛЬКО голос диктора, диапазон
            громкости - 11,5 dB до -12,0 dB</p></li>
          <li><p className={styles.textInWhite}>Громкость измеряется не по всей длине трека, а по самой динамически
            нагруженной его части</p></li>
          <li><p className={styles.textInWhite}>Наличие исключительных прав на мелодию</p></li>
          <li>
            <p className={styles.textInWhite}>
              Максимальный размер аудиоролика – <br/>15 мб
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export const Wiki52Aud = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Если у вас собственный аудиоролик, сверьтесь с нашими требованиями:</span>
        <ul style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Все загруженные вами рекламные материалы будут проверены нашими
            сотрудниками перед запуском рекламной кампании</p></li>
          <li><p className={styles.textInWhite}>Если ваш ролик не пройдет модерацию, наш специалист свяжется с вами для
            решения вопроса</p></li>
        </ul>
      </div>
    </>
  );
};

export const Wiki53Aud = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <ol style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Реклама не должна побуждать к насилию, агрессии и опасным действиям и
            нарушать этические нормы</p></li>
          <li>
            <p className={styles.textInWhite}>
              Все рекламные материалы должны соответствовать Федеральному
              &nbsp;<a
                href="https://docs.cntd.ru/document/901971356" rel="noreferrer"
                target="_blank" className={styles.link} style={{ display: 'inline', textDecoration: 'none' }}>
                закону от 03.08.2018 No 325-ФЗ «О рекламе»</a>
            </p>
          </li>
          <li><p className={styles.textInWhite}>Запрещается реклама, негативно влияющая на имидж компании, например,
            реклама ритуальных услуг</p></li>
          <li><p className={styles.textInWhite}>Запрещается реклама религии и политики</p></li>
          <li><p className={styles.textInWhite}>Запрещается транслировать рекламу всех продуктовых сетей, являющихся
            прямыми конкурентами, но не запрещается реклама зонтичных брендов</p></li>
          <li><p className={styles.textInWhite}>Запрещается рекламировать онлайн продуктовый ритейл специализирующийся
            на продуктах</p></li>
          <li><p className={styles.textInWhite}>Запрещается реклама сервисов доставки еды (но не запрещается реклама
            доставки готовой еды)</p></li>
          <li><p className={styles.textInWhite}>Запрещается реклама о наборе персонала, то есть запрещается конкретный
            призыв «приходи работать в компанию стороннюю и получай по 50к в месяц», но не запрещается реклама сайтов
            поиска работы, таких как HH и другие</p></li>
          <li><p className={styles.textInWhite}>Табачная продукция, включая электронные сигареты</p></li>
          <li><p className={styles.textInWhite}>Микрозаймы, ломбарды, сомнительные инвестиционные активности</p></li>
          <li><p className={styles.textInWhite}>Банки без наличия действующей лицензии</p></li>
        </ol>
      </div>
    </>
  );
};

export const Wiki54Aud = () => {
  return (
    <>
      <p className={styles.textInWhite}>
        По результатам завершенной рекламной кампании вы получите отчет с ключевыми показателями
      </p>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Состав отчета</span>
        <ul style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Даты размещения</p></li>
          <li><p className={styles.textInWhite}>Адреса магазинов</p></li>
          <li><p className={styles.textInWhite}>Количество выходов аудиороликов</p></li>
          <li><p className={styles.textInWhite}>Стоимость РК</p></li>
        </ul>
      </div>
    </>
  );
};

export const Wiki47Soc = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>
          Мы разместим ваш пост в социальных сетях ВКонтакте и/или Одноклассники, или опубликуем пост в каналах в Телеграм.
        </p>
        <p className={styles.textInWhite}>
          Размещения будут завесить напрямую от администраторов площадки и рекламного креатива, мы даем администраторам инструмент для размещения рекламы и показываем контент поста. Если на площадке есть в данный момент рекламные места, и креатив устраивает ресурс, то он будет размещен.
        </p>
        <p className={styles.textInWhite}>
          В рекламе участвуют только качественные ресурсы, у которых не менее 15 тыс подписчиков в группах ВКонтакте и Одноклассники, и не менее 1000 подписчиков в Телеграм каналах. Каждая площадка при добавлении проходит ручную модерацию, во время которой рассматривается тематика, контент, аналитика аудитории и источников трафика.
        </p>
        <p className={styles.textInWhite}>
          Рекламное сообщение увидят все подписчики и читатели группы, публичной страницы или канала без ограничения по географии и каким-либо соцдем параметрам.
        </p>
      </div>
    </>
  );
};

export const Wiki47 = ({ onOpenModal }: { onOpenModal: () => void }) => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>4 формата Digital-щитов:</span>
        <LinkOpenInfo className={styles.linkModal} onClick={onOpenModal}>Смотреть примеры билбордов</LinkOpenInfo>
        <ol style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Билборд (DBB) – это рекламный щит большого формата, заметный на
            расстоянии более 15 м. Стандартный размер — 3 х 6 м</p></li>
          <li><p className={styles.textInWhite}>Ситиборд (DCB) – это рекламный короб с внутренней подсветкой размером
            3,7 x 2,7 м</p></li>
          <li><p className={styles.textInWhite}>Суперсайт – это рекламная конструкция размером 4 х 12 или 5 х 15 м.
            Суперсайты больше билбордов, поэтому часто располагаются за пределами города, на широких улицах и
            автомобильных развязках</p></li>
          <li><p className={styles.textInWhite}>Ситиформат – отдельно стоящие конструкции наружной рекламы, павильоны
            ожидания транспорта</p></li>
        </ol>
      </div>
    </>
  );
};

export const Wiki49Soc = ({ onOpenModal }: { onOpenModal: () => void }) => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Как подготовить креатив для поста:</span>
        <span className={styles.link2} onClick={onOpenModal}>Смотреть примеры оформления постов</span>
        <p className={styles.textInWhite}>Пост в телеграм:</p>
        <ul style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Текст до 4 096 символов (Если пост без изображений)</p></li>
          <li><p className={styles.textInWhite}>Текст до 1 024 символов (Если пост с изображениями)</p></li>
          <li><p className={styles.textInWhite}>Максимальный размер файла до 50Мб</p></li>
          <li><p className={styles.textInWhite}>Формат файла: png .jpg .jpeg .gif</p></li>
          <li><p className={styles.textInWhite}>Если к посту будет прикреплена кнопка, то допустимо использование
            только <br/>1 файла</p></li>
          <li><p className={styles.textInWhite}>До 50 символов текста допускается на кнопке</p></li>
        </ul>
        <span>Пост в ВКонтакте/Одноклассники: </span>
        <ul style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Текст до 4 096 символов</p></li>
          <li><p className={styles.textInWhite}>Максимальный размер файла до 50Мб</p></li>
          <li><p className={styles.textInWhite}>Формат файла: png .jpg .jpeg .gif</p></li>
        </ul>
        {/* <span>Репост в ВКонтакте/Одноклассники:</span>
        <ul style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Ссылка на пост рекламодателя из открытой группы/паблика</p></li>
        </ul> */}
        <p className={styles.textInWhite}>* Все рекламные материалы должны соответствовать применимому законодательству
          и&nbsp;<a
            href="https://static.beeline.ru/upload/images/business/doc/redpolitika_beeline.pdf" rel="noreferrer"
            target="_blank" className={styles.link} style={{ display: 'inline', textDecoration: 'none' }}>
            Редакционной
            политике билайн</a>
        </p>
      </div>
    </>
  );
};

export const Wiki48Soc = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Порядок запуска:</span>
        <ol style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Заполните все обязательные поля брифа</p></li>
          <li><p className={styles.textInWhite}>Наш специалист получит вашу заявку и, при необходимости, свяжется с вами
            для уточнения деталей и подтверждения заявки</p></li>
          <li><p className={styles.textInWhite}>Вы оплачиваете стоимость рекламной кампании через личный кабинет</p>
          </li>
          <li><p className={styles.textInWhite}>После поступления денег и прохождения модерации рекламная кампания будет
            запущена</p></li>
          <li><p className={styles.textInWhite}>После окончания рекламной кампании вы получите отчет</p></li>
        </ol>
      </div>
    </>
  );
};


export const Wiki50Soc = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Советы по подготовке текста:</span>
        <ul style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Пишите то, что будет интересно пользователю – первые десять слов
            сообщения должны стать «‎приманкой»‎, чтобы дочитать сообщение до конца
          </p></li>
          <li><p className={styles.textInWhite}>Отсутствие абзацев, пробелов, знаков препинания мешают уловить смысл
            текста, каждая мысль должна начинаться с нового абзаца
          </p></li>
          <li><p className={styles.textInWhite}>Разумное использование эмодзи и форматирования подчеркнет настроение
            сообщения. Главное, не перестараться!
          </p></li>
          <li><p className={styles.textInWhite}>Чтобы пользователь перешел на сайт или совершил запланированное
            действие, необходимо добавить призыв, например, «‎Перейти»‎, «‎Купить сейчас»‎, «‎Узнать больше»‎,
            «‎Получить подарок»‎, «‎Применить промокод»‎ – варианты конверсионных призывов, которые навигируют
            пользователя на пути к покупке и помогают извлечь пользу из предложения. Призыв можно оформить в виде кнопки
            в посте в Телеграм
          </p></li>
          <li><p className={styles.textInWhite}>В тексте должна содержаться обязательная юридическая информация, если
            рекламируемый продукт или услуга этого требуют. Данная информация включается в общее количество символов
          </p></li>
        </ul>
      </div>
    </>
  );
};
export const Wiki51Soc = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Советы по подготовке изображений:</span>
        <ul style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Рекламное сообщение можно дополнить картинкой или gif-анимацией. Можно
            использовать несколько картинок
          </p></li>
          <li><p className={styles.textInWhite}>
            Картинка не должна демонстрировать жестокость, насилие, оружие, нецензурные жесты, не должна изображать
            сцены употребления алкоголя или табака, сцены эротического характера и другие запрещенные <a
              href="https://static.beeline.ru/upload/images/business/doc/redpolitika_beeline.pdf" target="_blank"
              rel="noreferrer"
              style={{ color: '#9315B3', cursor: 'pointer' }}>Редакционной
              политикой билайн</a> и законодательством изображения
          </p></li>
          <li><p className={styles.textInWhite}>Желательно написать на картинке несколько слов, которые передадут суть
            послания
          </p></li>
          <li><p className={styles.textInWhite}>Не забывайте об ответственности за использование картинок без разрешения
            правообладателя!
          </p></li>
        </ul>
      </div>
    </>
  );
};

export const Wiki52Soc = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Требования к изображению граждан в рекламе</span>
        <ul style={{ fontSize: 16, paddingLeft: 16 }}>
          <li><p className={styles.textInWhite}>Изображение граждан в рекламе допускается только с их письменного
            согласия (Ч. 1 ст. 152.1 Гражданского кодекса РФ). Соответственно, рекламодатель до начала размещения такой
            рекламы должен предоставить либо копию согласия гражданина (согласия родителей, если гражданин
            несовершеннолетний) на использование его изображения в рекламе, либо гарантийное письмо, заверенное подписью
            и печатью рекламодателя

          </p></li>
          <li><p className={styles.textInWhite}>Если у вас заключено дополнительное соглашение к договору оказания
            услуг, в котором имеется согласие на использование изображений граждан, то дополнительно ничего
            предоставлять не нужно

          </p></li>
        </ul>
      </div>
    </>
  );
};

export const Wiki48 = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>Рекламу увидит аудитория, которая в момент рекламы находилась в
          непосредственной близости к билборду</p>
      </div>
    </>
  );
};

export const Wiki53Soc = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>Бюджет для запуска — от 10 000 ₽ с НДС. Услуги, стоимостью ниже указанного
          бюджета, не оказываются</p>
      </div>
    </>
  );
};

export const Wiki49 = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Порядок запуска:</span>
        <ol style={{ padding: 16 }}>
          <li><p className={styles.textInWhite}>Заполните все обязательные поля брифа. Загрузите свой креатив в
            предложенных размерах или закажите создание баннера у нас</p></li>
          <li><p className={styles.textInWhite}>Наш специалист получит вашу заявку и свяжется с вами для уточнения
            деталей и подтверждения заявки, а также озвучит наличие выбранных вами вариантов размещения</p></li>
          <li><p className={styles.textInWhite}>Вы оплачиваете стоимость рекламной кампании через личный кабинет</p>
          </li>
          <li><p className={styles.textInWhite}>После поступления денег и прохождения модерации рекламная кампания будет
            запущена</p></li>
          <li><p className={styles.textInWhite}>После окончания рекламной кампании вы получите отчет</p></li>
        </ol>
      </div>
    </>
  );
};

export const Wiki50 = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Как подготовить баннер:</span>
        <ol style={{ padding: 16 }}>
          <li><p className={styles.textInWhite}>На баннере должен быть логотип или название вашей компании/бренда</p>
          </li>
          <li><p className={styles.textInWhite}>Ваше предложение на баннере должно быть понятным и привлекательным</p>
          </li>
          <li><p className={styles.textInWhite}>Получатели должны найти в тексте ответы на два вопроса: 1) Что вы
            предлагаете? 2) Почему это выгодно?</p></li>
          <li><p className={styles.textInWhite}>Клиенты должны понимать, какие действия вы от них ожидаете, то есть
            баннер должен содержать адрес, номер телефона или сайт</p></li>
          <li><p className={styles.textInWhite}>Баннер должен соответствовать требованиям применимого
            законодательства, <a target="_blank" rel="noreferrer"
              href="https://static.beeline.ru/upload/images/business/doc/redpolitika_beeline.pdf"
              className={styles.link}>Рекламной политики билайн</a> и техническим требованиям
            рекламных конструкций</p></li>
        </ol>
      </div>
    </>
  );
};

export const Wiki51 = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Размеры баннеров для размещения:</span>
        <p className={styles.textInWhite}>
          Необходимо загрузить медиафайл в разных разрешениях. Мы возьмем нужные размеры в зависимости от вида билборда
          в выбранной геолокации:
        </p>
        <ul className={styles.list}>
          <li><p className={styles.textInWhite}>1440*720</p></li>
          <li><p className={styles.textInWhite}>1248*416</p></li>
          <li><p className={styles.textInWhite}>1728*576</p></li>
          <li><p className={styles.textInWhite}>1440*480</p></li>
          <li><p className={styles.textInWhite}>160*600</p></li>
          <li><p className={styles.textInWhite}>864*288</p></li>
          <li><p className={styles.textInWhite}>1200*480 и другие*</p></li>
        </ul>
        <p className={styles.textInWhite}>
          * В случае размещения вашего ролика на баннерах других размеров, мы можем попросить вас прислать исходник
          баннера в psd для адаптации материала к размещению
        </p>
      </div>
    </>
  );
};

export const Wiki52 = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Общие требования:</span>
        <ul className={styles.list}>
          <li className={styles.listItem}><p className={styles.textInWhite}>Информация должна считываться за время
            трансляции рекламного материала. Продолжительность трансляции одного ролика (сюжета) устанавливается
            оператором на каждой рекламной конструкции</p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>Не располагайте важные информационные
            элементы «в край» рекламной конструкции: оставляйте отступы</p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>Все рекламные материалы должны
            соответствовать требованиям применимого законодательства, <a target="_blank" rel="noreferrer"
              href="https://static.beeline.ru/upload/images/business/doc/redpolitika_beeline.pdf"
              className={styles.link}>Рекламной политики
              билайн</a> и техническим требованиям рекламных конструкций</p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>Минимальный размер шрифта определяется по
            самой маленькой букве в соответствии с форматом изображения</p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>
            Общие требования к цифровому постеру: длительность: 5 сек (125 кадров), ролик должен быть без звука.</p>
          </li>
          <li className={styles.listItem}>
            <ul className={styles.textInWhite} style={{ padding: 0, margin: 0, listStyleType: 'disc' }}>
              Полные технические требования к креативу для размещения :
              <li style={{ marginLeft: '30px' }}>
                <a target="_blank" rel="noreferrer"
                  href="https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/%D0%A2%D0%B5%D1%85%D0%BD%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5_%D1%82%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F_%D0%BA_%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D1%83_%D0%B4%D0%BB%D1%8F_%D1%80%D0%B0%D0%B7%D0%BC%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BD%D0%B0_%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D1%8B%D1%85_%D0%B1%D0%B8%D0%BB%D0%B1%D0%BE%D1%80%D0%B4%D0%B0%D1%85.pdf"
                  className={styles.link2}>
                  На цифровых билбордах
                </a>
              </li>

              <li style={{ marginLeft: '30px' }}>
                <a target="_blank" rel="noreferrer"
                  href="https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/%D0%A2%D0%B5%D1%85%D0%BD%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5_%D1%82%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F_%D0%BA_%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D1%83_%D0%B4%D0%BB%D1%8F_%D1%80%D0%B0%D0%B7%D0%BC%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BD%D0%B0_%D1%81%D1%83%D0%BF%D0%B5%D1%80%D1%81%D0%B0%D0%B9%D1%82%D0%B0%D1%85.pdf"
                  className={styles.link2}>
                  На суперсайтах
                </a>
              </li>

            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export const Wiki53 = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <ol className={styles.list}>
          <li className={styles.listItem}><p className={styles.textInWhite}>Бюджет для запуска – от 15 000 ₽ с НДС</p>
          </li>
          {/* <li className={styles.listItem}><p className={styles.textInWhite}>Стоимость создания баннера – <br />от 3500 ₽ с
            НДС</p></li> */}
          <li className={styles.listItem}><p className={styles.textInWhite}>Услуги, стоимостью ниже указанного бюджета,
            не оказываются</p></li>
        </ol>
      </div>
    </>
  );
};

export const Wiki54 = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <ul className={styles.list}>
          <li className={styles.listItem}><p className={styles.textInWhite}>Все загруженные вами рекламные материалы
            будут проверены нашими сотрудниками перед запуском рекламной кампании</p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>Если ваш креатив не пройдет модерацию, наш
            специалист свяжется с вами для решения вопроса</p></li>
        </ul>
      </div>
    </>
  );
};

export const Wiki55 = () => {
  return (
    <>
      <p className={styles.text}>По результатам завершенной рекламной кампании вы получите отчет с ключевыми
        показателями</p>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Состав отчета</span>
        <ul className={styles.list}>
          <li className={styles.listItem}><p className={styles.textInWhite}>Даты размещения</p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>Количество контактов*</p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>Стоимость РК</p></li>
        </ul>
        <p className={styles.textInWhite}>* Контакт – случай демонстрации рекламы прохожему, который находится рядом с
          рекламной конструкцией в момент трансляции</p>
      </div>
    </>
  );
};


export const Wiki55Soc = () => {
  return (
    <>
      <p className={styles.text}>По результатам завершенной рекламной кампании вы получите отчет с ключевыми
        показателями</p>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Состав отчета</span>
        <ul className={styles.list}>
          <li className={styles.listItem}><p className={styles.textInWhite}>Тематики площадок</p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>Дата начала и окончания кампании</p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>Количество размещений</p></li>

          <li className={styles.listItem}><p className={styles.textInWhite}>Количество показов всего и до площадки
          </p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>Количество переходов (количество кликов по
            ссылке из поста)
          </p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>CTR (соотношение кликов к показам,
            указывающее, как часто пользователи нажимают на ваш пост)
          </p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>% вовлеченности (показатель активности
            аудитории: чем выше процент, тем аудитория активнее взаимодействовала (кликала, репостила) с рекламным
            постом. Рассчитывается как отношение количества взаимодействий с постом к количеству показов в ленте)
          </p></li>
          <li className={styles.listItem}><p className={styles.textInWhite}>Стоимость РК</p></li>
        </ul>
      </div>
    </>
  );
};

export const Wiki56 = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>За дополнительную плату мы подготовим для вас отчет об эффективности кампании.
          Обратитесь к менеджеру и он сориентирует вас по дальнейшим шагам</p>
      </div>
    </>
  );
};

export const Wiki56Soc = () => {
  return (
    <>
      <div className={styles.whiteBlock}>
        <p className={styles.textInWhite}>В отчете после завершения рекламной кампании будут все данные, собранные на
          каждом шаге воронки</p>
        <p className={styles.textInWhite}>Не забывайте размечать ссылку для перехода utm-метками (теги для аналитики
          рекламной кампании), чтобы увидеть статистику в вашей аналитической системе</p>
      </div>
    </>
  );
};

export const Wiki57 = () => {
  return (
    <>
      <p className={styles.textInWhite}>1 сентября 2022 года в России вступил в силу закон № 347-ФЗ о поправках в
        Федеральный закон «О рекламе». По новым правилам интернет-рекламу нужно маркировать пометкой «Реклама»,
        указывать рекламодателя; проставлять токен (уникальный идентификатор рекламного объявления) и передавать
        информацию о рекламных кампаниях в Единый реестр интернет-рекламы (ЕРИР) через оператора рекламных данных
        (ОРД)</p>
      <p className={styles.textInWhite}>Вы уже сотрудничаете с ОРД? Если да – выберите «Есть токен», и запросите номер в
        вашем ОРД, если нет – выберите «Нет токена» и мы присвоим токен вашей рекламе, а также отправим данные о вас и
        вашей рекламной кампании в ОРД</p>
    </>
  );
};

export const Wiki58 = () => {
  return (
    <>
      <p className={styles.textInWhite}>
        Интернет-реклама в формате текстовых объявлений
        в поисковой сети Яндекс определенной целевой аудитории
        (например, только женщинам 25-50 лет из Москвы, которые интересуются путешествиями за границу).
        Рекламный сегмент формируется билайн при наличии согласия пользователя на обработку данных
        и получение рекламы
      </p>
    </>
  );
};

export const Wiki59 = () => {
  return (
    <ol style={{ padding: 16 }}>

      <li className={styles.listItem}>
        Заполните все обязательные поля заявки в аккаунте
      </li>

      <li className={styles.listItem}>
        На основании данных из заполненной заявки подготовим и отправим на согласование заказ со списком ключевых слов и
        текстом рекламных объявлений.
        Корректировку в заказ можно внести только 1 раз до момента запуска рекламной кампании
      </li>

      <li className={styles.listItem}>
        Вы оплачиваете стоимость рекламной кампании через личный кабинет
      </li>

      <li className={styles.listItem}>
        После поступления средств, мы подготовим кампанию к запуску и отправим на модерацию Яндекса
      </li>

      <li className={styles.listItem}>
        После прохождения модерации рекламная кампания будет запущена
      </li>

      <li className={styles.listItem}>
        По окончанию рекламной кампании вы получите отчет с количеством кликов,
        показов и другими основными параметрами рекламной кампании
      </li>

    </ol>
  );
};

export const Wiki60 = () => {
  return (
    <>
      <p className={styles.textInWhite}>
        Подготовим текстовые форматы объявлений.
        Создадим объявления за клиента и запустим рекламную кампанию. Ответственность за содержание рекламных материалов
        несет клиент
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '170px' }}>
          <img src={imgContextual1} className={styles.img2} alt="example"/>
          <span style={{ fontSize: '16px', fontFamily: 'TT Interfaces' }}>В поиске</span>
        </div>
      </div>
    </>
  );
};

export const Wiki61 = () => {
  return (
    <>
      <p className={styles.text}>
        Объявление увидит аудитория, использовавшая ключевые слова из вашей тематики в поисковике Яндекс.
      </p>
      <div>
        <span className={styles.listTextTitle}>Для подбора аудитории мы используем:</span>
        <ul className={styles.list}>
          <li className={styles.listItem}>аудиторию из абонентов билайн, собранную по вашему запросу</li>
          <li className={styles.listItem}>ключевые слова продвигаемой категории услуг по данным Яндекса</li>
        </ul>
      </div>
    </>
  );
};


export const Wiki62 = () => {
  return (
    <>
      <p className={styles.text}>
        По результатам завершенной рекламной кампании вы получите отчет с ключевыми показателями
      </p>
      <div>
        <span className={styles.listTextTitle}>Состав отчета:</span>
        <ul className={styles.list}>
          <li className={styles.listItem}>Итоговая стоимость кампании</li>
          <li className={styles.listItem}>Количество показов</li>
          <li className={styles.listItem}>Количество кликов</li>
          <li className={styles.listItem}>Конверсия в клики (CTR)</li>
          <li className={styles.listItem}>Стоимость клика</li>
          <li className={styles.listItem}>Период кампании</li>
        </ul>
      </div>
    </>
  );
};

type Props = {
  isOpen?: boolean
};

export const Wiki63: FC<Props> = ({ isOpen }) => {
  useEffect(() => {
    if (!isOpen) return;
    const element: any = document.getElementById('go');
    element.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Как рассчитывается стоимость клика?</span>
        <p className={styles.textInWhite}>
          Стоимость клика рассчитывается индивидуально, исходя из отрасли бизнеса и географии продвижения.
          Итоговая стоимость клика будет указана в заказе на услугу
        </p>
      </div>
      <div className={styles.whiteBlock}>
        <span
          className={styles.title}>Что делать, если открутка по итогу произойдет на меньшее количество кликов?</span>
        <p className={styles.textInWhite}>
          В случае оставшихся средств мы произведем возврат на «Кошелек» личного кабинета билайн.ПРОдвижение
        </p>
      </div>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Если в ходе рекламной кампании появятся корректировки, можно ли их внести?</span>
        <p className={styles.textInWhite}>
          Внесение изменений в параметры и условия размещения рекламной кампании, согласованные в заказе, а также
          приостановка/досрочное прекращения рекламной кампании, не предусмотрены
        </p>
      </div>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Какое минимальное и максимальное количество дней может крутиться рекламная кампания?</span>
        <p className={styles.textInWhite}>
          Период кампании определяется автоматически, в зависимости от указанного бюджета (в среднем 30 дней).
          По запросу, мы можем настроить рекламную кампанию периодом от 14 дней
        </p>
      </div>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>Могу ли я следить за результатами РК в ходе запуска?</span>
        <p className={styles.textInWhite}>
          На данном этапе нет, но в случае необходимости, мы можем предоставить отчет о рекламной кампании не чаще 1
          раза в течение 2 недель в пределах проведения рекламной кампании
        </p>
      </div>
      <div className={styles.whiteBlock}>
        <span className={styles.title}>У меня уже есть аккаунт в Яндекс.Директе, я могу его вам передать?</span>
        <p className={styles.textInWhite}>
          Нет, мы создаем рекламную кампанию с нуля
        </p>
      </div>
      <div className={styles.whiteBlock}>
        <span className={styles.title} id="go">Как отслеживать эффективность рекламной кампании?</span>
        <ol style={{ padding: 16 }}>
          <li className={styles.listItem}>
            Для отслеживания статистики в Яндекс Метрике, используйте utm метки.
            Для создания UTM-меток вы можете воспользоваться сервисом нашего партнера:
            <a target="_blank" rel="noreferrer"
              href="https://callibri.ru/generator_utm_metok"
              className={styles.link3}>https://callibri.ru/generator_utm_metok</a>
          </li>
          <li className={styles.listItem}>
            Если у вас не установлен счетчик в Яндекс.Метрике, вы можете воспользоваться инструкцией по его установке от
            нашего партнера:
            <a target="_blank" rel="noreferrer"
              href="https://blog.callibri.ru/kak-ustanovit-yandeks-metriku-na-sayt"
              className={styles.link3}>https://blog.callibri.ru/kak-ustanovit-yandeks-metriku-na-sayt</a>
          </li>
        </ol>
      </div>
    </>
  );
};

export const Wiki64 = () => {
  return (
    <ul style={{ padding: 16, lineHeight: 'normal' }}>

      <li className={styles.listItem}>
        Голосовой обзвон – это рекламный продукт, с помощью которого можно позвонить своей целевой аудитории и предложить им свои товары или услуги
      </li>

      <li className={styles.listItem}>
        Нет ограничений по времени разговора, поэтому можно максимально подробно озвучить свое предложение, а так же сразу подобрать для абонента индивидуальные условия
      </li>

      <li className={styles.listItem}>
        Голосовой обзвон – единственный рекламный продукт, в котором можно отработать возражения абонента в режиме реального времени
      </li>

      <li className={styles.listItem}>
        Продукт подходит как для узкого таргетированного подхода к аудитории для рекламы определенных предложений, так и для охватных компаний
      </li>

      <li className={styles.listItem}>
        Работа возможна только по абонентам билайн, в выборку попадают только те абоненты, которые дали свое согласие на получение рекламной информации. Все номера зашифрованы и передаются в обезличенном виде.
      </li>

    </ul>
  );
};

export const Wiki65 = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <img
        alt="Превью"
        src={preview}
        className={styles.video}
        onClick={() =>
          setShowModal(true)}
      />
      { showModal &&
      <PortalWrapper>
        <PopupStateless customCloseClass={styles.closeBtn} className={styles.popupVideo} opened onClose={() => setShowModal(false)}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            poster="https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/VTPreview.png"
            className={styles.videoBlock}
            controls
        >
            <source src="https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/%D0%93%D0%BE%D0%BB%D0%BE%D1%81%D0%BE%D0%B2%D0%BE%D0%B9%20%D0%BE%D0%B1%D0%B7%D0%B2%D0%BE%D0%BD_10.mp4" />
            Вы можете скачать видео здесь:
            <a href="https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/Голосовой обзвон_10.mp4">Презентация</a>
          </video>
        </PopupStateless>
      </PortalWrapper>
      }
    </>
  );
};

export const Wiki66 = () => {
  return (
    <ol style={{ padding: 16, lineHeight: 'normal' }}>

      <li className={styles.listItem}>
        Выберите параметры аудитории
      </li>

      <li className={styles.listItem}>
        Заполните заявку: укажите сферу деятельности, планируемый рекламный бюджет и период рекламной кампании
      </li>

      <li className={styles.listItem}>
        Выберите самостоятельный обзвон или индивидуальные условия
      </li>

      <li className={styles.listItem}>
        После отправки заявки с вами свяжется менеджер для подтверждения параметров выборки и определения способа настройки обзвона
      </li>

      <li className={styles.listItem}>
        Оплатите стоимость рекламной кампании через личный кабинет
      </li>

      <li className={styles.listItem}>
        Приступайте к обзвону
      </li>

    </ol>
  );
};

export const Wiki67 = () => {
  return (
    <div className={styles.text}>
      При таком формате работы происходит сбор абонентов на основе их поведения за определенный прошедший период.
      Вы выбираете параметры и глубину анализа аудитории.
      Выборка для обзвона приходит вам на почту единоразово в полном объеме
    </div>
  );
};

export const Wiki68 = () => {
  return (
    <div className={styles.text}>
      При таком формате работы вы можете получать абонентов на регулярной основе с выбранной вами периодичностью – раз в час или раз в сутки.
      В выборку будут попадать только самые «свежие» абоненты, у которых за указанный период произошло указанное событие.
      Например, абонент мог проявить интерес к определенным сайтам или номерам отправителей.
      Такая механика доступна в тарифе «Индивидуальные условия»
    </div>
  );
};

export const Wiki69 = () => {
  return <p className={styles.lightText}>Да! билайн может помочь найти и обзвонить ваших потенциальных клиентов. Для этого достаточно лишь следовать простой инструкции</p>;
};
