import React, { useEffect, useState } from 'react';
import Snowfall from 'react-snowfall';
import srcImg1 from './assets/pngegg1.png';
import srcImg2 from './assets/pngwing.com2.png';
import srcImg3 from './assets/pngegg3.png';
import srcImg4 from './assets/pngwing4.com.png';
import FlyingSanta from './Santa';

const snowflake1 = document.createElement('img');
const snowflake2 = document.createElement('img');
const snowflake3 = document.createElement('img');
const snowflake4 = document.createElement('img');

snowflake1.src = srcImg1;
snowflake2.src = srcImg2;
snowflake3.src = srcImg3;
snowflake4.src = srcImg4;

const images = [snowflake3, snowflake2, snowflake1, snowflake4];


const SnowFallComponent = () => {
  const [countSnow, setCountSnow] = useState(150);
  const countSnowFn = () => {
    setCountSnow(countSnow - 10);
  };

  useEffect(() => {
    if (countSnow > 20) {
      setTimeout(countSnowFn, 5000);
    }
  }, [countSnow]);


  return (
    <>
      {/* <FlyingSanta/> */}
      <Snowfall
        speed={[0, 2]}
        color="red"
        snowflakeCount={countSnow}
        radius={[1, 20]}
        images={images}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
        }}
      />
    </>
  );
};

export default SnowFallComponent;
